import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Webdesign = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4 bg-light" >
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-4 mt-4 text-center " >                        
                    <img className="card-img-top py-4" src="/img/web-design.png" alt="SoftehPlus" style={{ width: "18rem" }}></img> 
                    </div>                  
                   
                    <div className="col-md-8 mt-4 text-center  text-muted" >      
                    <h5 className="text-light mt-4"></h5>                  
                        <p style={{ textAlign: "justify" }}><span className="text-primary h5">Site-ul dvs. web,</span> este cel mai important instrument de marketing,
                         primul contact cu clientul, sau mai bine zis imaginea companiei tale, astfel încât colaborarea cu o companie experimentată de web design este crucială pentru 
                         a vă asigura că atrage vizitatori relevanți, implică utilizatorii, oferă informații utile sau duce la creșterea vânzărilor. In acesta lume digitala, prima 
                         impresie a unui client este vitala, asa ca noi a o sa va oferim o aplicatie cu o functionalitate de calitate, o estetica atragatoare si nu in ultimul rand 
                         responsiv, adica navigarea cu usurinta pe orice dispozitiv.</p>
                        <p style={{ textAlign: "justify" }}><span className="text-primary h5">Dezvoltarea web,</span> este un termen larg, care cuprinde 
                        orice activitate legată de dezvoltarea unui site web pentru World Wide Web sau intranet. Aceasta poate include dezvoltarea afacerilor prin comerț 
                        electronic (e-commerce), web design, dezvoltarea de conținut web, programare specifică, configurarea serverelor web, etc. Dezvoltarea web include 
                        atât realizarea unor simple pagini web statice cu text, până la cele mai complexe aplicații Internet, afaceri electronice (ebusiness), sau servicii 
                        de rețele sociale. </p>
                        <p style={{ textAlign: "justify" }}><span className="text-primary h5"> În prezent, </span> preocuparea pentru aspectul grafic al siturilor, devenite 
                        puternice instrumente comerciale și publicitare, justifică în întregime denumirea de „design web”, iar tehnologiile utilizate s-au diversificat și au devenit 
                        din ce în ce mai complexe. Companiile care produc site-uri lucrează cu angajați specializați pentru fiecare etapă a dezvoltării unui site, de la stadiul 
                        de concepție grafică la programare și editare de conținut pentru SEO. </p>
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default Webdesign;