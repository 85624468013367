import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../auth";
//import { Link } from "react-router-dom";
import { createProduct, getCategories } from "./apiAdmin";

const AddProduct = () => {
    const [values, setValues] = useState({
        plucru: "",
        description: "",       
        categories: [],
        category: "",
        adminuser: "",
        lufuser: "",        
        resetpass: "",
        photo: "",
        loading: false,
        error: "",
        createdProduct: "",
        redirectToProfile: false,
        formData: ""
    });

    const { user, token } = isAuthenticated();
    const {
        plucru,
        description,        
        categories,        
        adminuser,
        lufuser,
        resetpass,
        loading,
        error,
        createdProduct,       
        formData
    } = values;

    // load categories and set form data
    const init = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    categories: data,
                    formData: new FormData()
                });
            }
        });
    };

    useEffect(() => {
        init();
    }, []);

    const handleChange = name => event => {
        const value =
            name === "photo" ? event.target.files[0] : event.target.value;
        formData.set(name, value);
        setValues({ ...values, [name]: value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        createProduct(user._id, token, formData).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    plucru: "",
                    description: "",
                    photo: "",
                    adminuser: "",
                    lufuser: "",
                    resetpass: "",
                    loading: false,
                    createdProduct: data.plucru
                });
            }
        });
    };

    const newPostForm = () => (
        <form className="mb-3" onSubmit={clickSubmit}>
            <h4>Selecteaza fotografie</h4>
            <div className="form-group">
                <label className="btn btn-secondary">
                    <input
                        onChange={handleChange("photo")}
                        type="file"
                        name="photo"
                        accept="image/*"
                    />
                </label>
            </div>

            <div className="form-group">
                <label className="text-muted">Punct de lucru</label>
                <input
                    onChange={handleChange("plucru")}
                    type="text"
                    className="form-control"
                    value={plucru}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Descriere</label>
                <textarea
                    onChange={handleChange("description")}
                    className="form-control"
                    value={description}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Admin User</label>
                <input
                    onChange={handleChange("adminuser")}
                    type="text"
                    className="form-control"
                    value={adminuser}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">LUF User</label>
                <input
                    onChange={handleChange("lufuser")}
                    type="text"
                    className="form-control"
                    value={lufuser}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Farmacie</label>
                <select
                    onChange={handleChange("category")}
                    className="form-control"
                >
                    <option>Selecteaza</option>
                    {categories &&
                        categories.map((c, i) => (
                            <option key={i} value={c._id}>
                                {c.name}
                            </option>
                        ))}
                </select>
            </div>          
             
            <div className="form-group">
                <label className="text-muted">Reset LUF Password</label>
                <input
                    onChange={handleChange("resetpass")}
                    type="number"
                    className="form-control"
                    value={resetpass}
                />
            </div>

            <button className="btn btn-outline-primary">Continua</button>
        </form>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: createdProduct ? "" : "none" }}
        >
            <h2>{`${createdProduct}`} a fost creat!</h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Asteapta...</h2>
            </div>
        );

    return (
        <Layout
            title="Adauga un client nou"
            description={`Buna ziua ${user.name}, esti pregatit pentru a adauga noi clienti?`}
        >
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                    {newPostForm()}
                </div>
            </div>
        </Layout>
    );
};

export default AddProduct;
