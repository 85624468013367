import React from "react";
import { Link, Redirect } from "react-router-dom";
import { isAuthenticated } from "../../components/auth";
import { Slider, Menu } from 'antd';
import {
  DesktopOutlined,
  InsertRowBelowOutlined,
  DatabaseOutlined,  
  FolderViewOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

const { SubMenu, Item } = Menu;

const redirectUser = () => {
  if(isAuthenticated){
    const { user  } = isAuthenticated();
}else{
  return <Redirect to="/signin" />
}};

const UserNav = () => (
  <Menu mode="inline" theme="light" >  
  <img src="/img/logo2.png" style={{ height: "50px", width: "100px" }} className="container col-md-8 offset-md-4" />
  <hr />
  <Item className="" icon={<DesktopOutlined />}>
    <Link to="/user/dashboard">
          Dashboard
    </Link>
  </Item>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="Sesizari"          
        >
    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/user/create/tiket" >
            Depune Sesizare
        </Link>
    </Item>

    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/user/view/tiket" >
            Arhiva Sesizari
        </Link>
    </Item>  
  </SubMenu>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="Profil utilizator"          
        >  
    <Item className="" icon={<DatabaseOutlined />}>
      <Link to={`/profile/${isAuthenticated().user._id}`} >
          Modifica Profil
      </Link>
    </Item>
    
    <Item className="" icon={<FolderViewOutlined />}>
      {redirectUser}
      <Link to="/" >
          Sterge profil
      </Link>
    </Item> 
  </SubMenu>   
</Menu>
);

export default UserNav;
