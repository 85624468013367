import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";
import { getProducts, getFilteredProducts} from "../../core/apiCore";
import SNVM from "../../core/SNVM";
import Search from "../../core/Search";

const Snvm = () => {   
    const [productsByArrival, setProductsByArrival] = useState([]);
    const [error, setError] = useState(false);

  

    const loadProductsByArrival = () => {
        getProducts("-updatedAt").then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setProductsByArrival(data);
            }
        });
    };



    useEffect(() => {
        loadProductsByArrival();        
    }, []);

    return (
        <Layout
            title="Clienti SNVM"
            description=""
            className="container-fluid"
        >
            <Search />
            <div className='container-fluid'>
            <h2 className="mb-4">Clienti SNVM</h2>
            <div className="row">
                {productsByArrival.map((product, i) => (
                    <div key={i} className="mt-3">
                        <SNVM product={product} />
                    </div>
                ))}
            </div>            
            </div>
        </Layout>
    );
};

export default Snvm;
