import React from "react";


const Solutii = () => {    
     
    return (
        <div>            
            <div className="container-fluid py-4 bg-light" >  
            <hr className="TextStyle"/>          
            <h2 className="text-title-blue text-center mt-4 py-4">Solutii software pentru sanatate</h2>                
                <div className="row d-flex justify-content-center text-center mt-4 py-4" >               
                <a className="text-sof" href="https://softeh.ro/farmacii/circuit-deschis" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/05/medisClinica-mockup-450x418.png" alt="Medis Clinica"  /><br />
                     MEDIS Clinica</a>
                <a className="text-sof" href="https://softeh.ro/sanatate/laborator/" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/05/medislaborator-mockup-450x418.png" alt="Medis Laborator"  /><br />
                     MEDIS Laborator</a> 
                <a className="text-sof" href="https://softeh.ro/sanatate/erp-centre-medicale-private" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/08/ERP-PAA-450x418.png" alt="Medis Laborator"  /><br />
                     POWER Account</a>
                <a className="text-sof" href="https://softeh.ro/sanatate/laborator/" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/08/telemedicina-450x418.png" alt="TeleMedis"  /><br />
                     TeleMEDIS</a>                                      
                <a className="text-sof" href="https://softeh.ro/sanatate/medicina_de_familie_medwin" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/05/medwin-mockup-450x418.png" alt="Medwin"  /><br />
                     MEDWIN</a>
                <a className="text-sof" href="https://softeh.ro/sanatate/spital/" target="blank">
                    <img style={{ width: "10rem" }} src="https://softeh.ro/wp-content/uploads/2020/04/medicalCMMockup-450x418.png" alt="Medical Cost"  /><br />
                     MEDICAL Cost</a>                       
                </div>
                <div className="row d-flex justify-content-center text-center mt-4" >
                <a className="text-sof " href="https://softeh.ro/sanatate/centru_medical_complex/" target="blank">
                    <img style={{ width: "20rem" }} src="https://softeh.ro/wp-content/uploads/2020/04/powerhospitalProductImg-450x418.png" alt="Power Hospital"  /><br />
                     POWER Hospital</a>
                </div>
            </div>        
        </div>        
    );
};

export default Solutii;