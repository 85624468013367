import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../core/Layout";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../../components/auth";
import { getCompanys } from "../../../functions/company";
import { createWorkstation, getWorkstations, removeWorkstation } from "../../../functions/workstation";
import WorkstationCreateForm from "../../../components/forms/WorkstationCreateForm";
import LocalSearch from "../../../components/forms/LocalSearch";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";


import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
  title: "", 
  companys: [],   
  company: "",
  adress: "",
  email: "", 
  phone: "",  
  description: "",
};

const WorkstationCreate = () => {
  const [values, setValues] = useState(initialState);  
  const [loading, setLoading] = useState(false);   
  const [keyword, setKeyword] = useState("");
  const [workstations, setWorkstations] = useState([]);

  const { user, token } = isAuthenticated();

  useEffect(() => {
    loadCompanys();
    loadWorkstation();
  }, []);

  const loadCompanys = () =>
    getCompanys().then((c) => setValues({ ...values, companys: c.data }));

    const loadWorkstation = () =>
    getWorkstations().then((w) => setWorkstations(w.data));


  const handleSubmit = (e) => {
    e.preventDefault();
    createWorkstation(values, user._id, token)
      .then((res) => {
        console.log(res);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status === 400) toast.error(err.response.data);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
     console.log(e.target.name, " ----- ", e.target.value);
  };

  const handleRemove = async (slug) => {
    // let answer = window.confirm("Delete?");
    // console.log(answer, slug);
    if (window.confirm("Delete?")) {
      setLoading(true);
      removeWorkstation(slug, user._id, token)
        .then((res) => {
          setLoading(false);
          toast.error(`${res.data.name} deleted`);
          loadWorkstation();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };

  const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);
 

  return (
    <Layout           
      description={`Buna ziua ${user.name}, aici poti sa creezi un punct de lucru nou.`}
      >      
      <div className="">
        <div className="row" style={{ minHeight: '80vh' }}>
          <div className="col-md-3">
            <AdminNav />
          </div>

          <div className="col-md-9">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Workstations create</h4>
            )}
            <hr />
            <WorkstationCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}  
                       
            />

            <LocalSearch keyword={keyword} setKeyword={setKeyword} />

            {/* step 5 */}
            {workstations.filter(searched(keyword)).map((w) => (
              <div className="alert alert-secondary" key={w._id}>
                {w.title}
                <span
                  onClick={() => handleRemove(w.slug)}
                  className="btn btn-sm float-right"
                >
                  <DeleteOutlined className="text-danger" />
                </span>
                <Link to={`/admin/effect/${w.slug}`}>
                  <span className="btn btn-sm float-right">
                    <EditOutlined className="text-warning" />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>      
    </Layout>
  );
};

export default WorkstationCreate;
