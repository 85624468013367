import axios from "axios";
import { API } from "../config";


export const createCompany = async (product, userId, token) =>
  await axios.post(`${API}/company/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeCompany = async (slug, userId, token) =>
  await axios.delete(`${API}/company/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getCompany = async (slug) =>
  await axios.get(`${API}/company/${slug}`);


export const updateCompany = async (slug, company, token) =>
  await axios.put(`${API}/company/${slug}`, company, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getCompanys = async () =>
  await axios.get(`${API}/companys`);

export const getCompanyRelated = async (companyId) =>
  await axios.get(`${API}/company/related/${companyId}`);

export const getCompanyWork = async (_id) =>
  await axios.get(`${API}/company/workstation/${_id}`);



