import React from "react";
import { Select } from "antd";

const { Option } = Select;

const EmployeeCreateForm = ({
    handleSubmit,
    handleChange,
    handleCompanyChange,
    handleWorkstationChange,
    showWork,
    setValues,
    values, 
    companys,
    workstations    
  }) => {
    // destructure
    const { name, email, password, phone, company, workstation } = values;
      
    return (
        <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                  <label>Company </label>
                  <select          
                    style={{ width: "100%" }}
                    className ="btn btn-info h6 text-primary"                                                          
                    onChange={handleCompanyChange}
                  >          
                    <option>Please select</option>
                    {companys.length > 0 &&
                      companys.map((c) => (
                          <option key={c.title} value={c._id}>
                              {c.title}
                          </option>                        
                      ))}
                  </select>
                </div>
                {showWork && (
                <div className="form-group">
                  <label>Workstation </label>
                  <select          
                    style={{ width: "100%" }}
                    className="btn btn-info h6 text-primary" 
                    name="workstation"
                    value={workstation}                                     
                    onChange={handleWorkstationChange}
                  > 
                    <option>Please select</option>
                    {workstations.length > 0 &&
                      workstations.map((c) => (
                          <option key={c.title} value={c._id}>
                              {c.title}
                          </option>                        
                      ))}
                  </select>
                </div>  
                )}
            </div>
                            
            <div className="col-md-6">
              <div className="form-group">
                <label>Telefon</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={email}
                  onChange={handleChange}
                />
              </div>   
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={[password]}
                  onChange={handleChange}
                />
              </div>      
            </div>         
          </div>
          <br />
          <button className="btn btn-outline-info">Save</button>
        </div>
    </form>
    );
  };
  
  export default EmployeeCreateForm;