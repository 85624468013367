import React from "react";
import Layout from "../Layout";
import Bottom from "../../components/home/Bottom"
import TopPage from "../../components/softeh/TopPage"
import PlusErp from "../../components/softeh/PlusErp"
import Avantaje from "../../components/softeh/Avantaje"
import Module from "../../components/softeh/Module"
import Solutii from "../../components/softeh/Solutii"
import BottomPage from "../../components/softeh/BottomPage"


const Softeh = () => {    
    

    return (
        <Layout
            title="WebNode Consulting"
            description="Consultant IT  |  Reprezentant Softeh"            
        >            
            <div className='main main-raised'>
                <div className="">
                    <TopPage />
                    <PlusErp />
                    <Avantaje />
                    <Module />
                    <Solutii />
                    <BottomPage />
                </div>
            <Bottom />
            </div>
        </Layout>
    );
};

export default Softeh;