import React from "react";


const TopPage = () => {    
    
    return (
        <div>
            <div className="container-fluid py-4 TextStyle" style={{background: "#292b2c"}}>            
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh.png" alt="Card" style={{ width: "5rem" }}></img><br/>                        
                            <h4 className="text-light">Softeh Plus</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }}>Digitalizarea documentelor a fost un pas natural, astfel incat incepand cu 2017 lucram la solutii care cresc automatizarea
                        fluxurilor si scad consumul de hartie si timp necesar completarii, stocarii si folosirii acestora. Acest proces este unul continuu si integreaza aplicatii, 
                        beneficiari, provideri de semnaturi digitale si clientii finali ai partenerilor nostri.

                        </p>
                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/webdesign.png" alt="Card" style={{ width: "5rem" }}></img><br/>
                        <h4 className="text-light">Asistenta tehnica IT</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} >Oferim servicii de suport tehnic pentru toate echipamentele de birou. Identificam rapid cauza problemelor si gidam utilizatorul pas cu pas pentru 
                        rezolvarea interactiva a acesteia. In cazul in care nu reusim telefonic, prin serviciul de asistenta tehnica, ne conectam remote la dumneavoastra si remediem problema sesizata.  </p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/admin.png" alt="Card" style={{ width: "5rem" }}></img><br/>
                        <h4 className="text-light">Web Development</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} >Doresti sa promovezi o afacere? Ai nevoie de o pagina web. Aceasta va fi imaginea ta online si in acelas timp lansarea afacerii tale catre succes. 
                            Poiectam si construim site-uri web de maxima calitate, prin care iti vei transforma vizitatorii in clienti, iar acestia la randul lor, in vanzatori ai serviciilor tale.   </p>                  
                    </div>                    
                </div>                                
            </div>               
        </div>        
    );
};

export default TopPage;