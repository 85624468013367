import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { isAuthenticated } from "../../../components/auth";
import { toast } from "react-toastify";
import Layout from "../../../core/Layout";
import { signup } from "../../../components/auth";
import CeoCreateForm from "../../../components/forms/CeoCreateForm";
import { getCompanys } from "../../../functions/company";
import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
    name: "",
    email: "",
    password: "",
    phone: "",    
    company: "",
    workstation: "",  
  };

const CeoCreate = () => {
    const [values, setValues] = useState(initialState);     
    const [companys, setCompanys] = useState([]); 
    const [loading, setLoading] = useState(false);
    
    const { name, email, password, phone, company, workstation } = values;
    const { user, token } = isAuthenticated();

    useEffect(() => {
        loadCompanys();        
      }, []);
    
    const loadCompanys = () =>
        getCompanys().then((c) => setCompanys(c.data));
    
      
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
         console.log(e.target.name, " ----- ", e.target.value);
      };

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        setValues({ ...values, error: false });
        signup({ name, email, password, phone, company, workstation: "602fb501bbf0e31fb8b8b3ec", role: 1 }).then(data => {
            if (data.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setValues({
                    ...values,
                    name: "",
                    email: "",
                    password: "",
                    phone: "",
                    company: "", 
                    workstation: "",                                     
                });
                toast.success(`"${name}" is created`);
                setLoading(false);
            }
        });
    };

    

    return (
        <Layout            
        description={`Buna ziua ${user.name}, aici poti sa creezi un CEO.`}            
        >        
             <div className="">
                <div className="row" style={{ minHeight: '75vh' }}>
                    <div className="col-md-3">
                        <AdminNav />
                    </div>                    
                     <div className="col-md-9">
                     {loading ? (
                        <LoadingOutlined className="text-danger h1" />
                        ) : (
                        <h4>CEO create</h4>
                        )}
                        <hr />
                        <CeoCreateForm
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            setValues={setValues}
                            values={values}
                            companys={companys}                            
                        />
                    </div>
                </div> 
            </div>        
        </Layout>
    );
};

export default CeoCreate;
