import React from "react";
import { Link } from "react-router-dom";
import { Slider, Menu } from 'antd';
import {
  DesktopOutlined,
  InsertRowBelowOutlined,
  DatabaseOutlined,  
  FolderViewOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

const { SubMenu, Item } = Menu;

const AdminNav = () => (
  <Menu mode="inline" theme="light" >    
  <img src="/img/logo2.png" style={{ height: "50px", width: "100px" }} className="container col-md-8 offset-md-4" />
  <hr />
  <Item className="" icon={<DesktopOutlined />}>
    <Link to="/admin/dashboard">
          Dashboard
    </Link>
  </Item>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="Company resource"          
        >
  <Item className="" icon={<InsertRowBelowOutlined />}>
    <Link to="/super/create/company" >
          Company Create
    </Link>
  </Item>

  <Item className="" icon={<InsertRowBelowOutlined />}>
    <Link to="/super/create/workstation" >
          Workstation Create
    </Link>
  </Item>

  <Item className="" icon={<InsertRowBelowOutlined />}>
    <Link to="/admin/create/employee" >
        Employee Create
    </Link>
  </Item>   

  </SubMenu>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="SNVM resource"          
        >  
    <Item className="" icon={<DatabaseOutlined />}>
      <Link to="/super/create/snvm" >
          Client Create
      </Link>
    </Item>
    
    <Item className="" icon={<FolderViewOutlined />}>
      <Link to="/super/view/snvm" >
          View Client
      </Link>
    </Item> 
  </SubMenu>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="Tiket resource"          
        >  
    <Item className="" icon={<FolderViewOutlined />}>
      <Link to="/admin/dashboard" >
          New Tikets
      </Link>
    </Item>
    <Item className="" icon={<FolderViewOutlined />}>
      <Link to="/taken/tikets" >
        Ticket in progress
      </Link>
    </Item>    
    <Item className="" icon={<FolderViewOutlined />}>
      <Link to="/history/tikets" >
          All Tikets
      </Link>
    </Item> 
  </SubMenu>

  <Item className="" icon={<FolderViewOutlined />}>
    <Link to="/super/portofolio/create">
          Portofolio
    </Link>
  </Item>   

  <Item className="" icon={<DollarCircleOutlined />}>
    <Link to="/super/contacts/clients">
          New Clients Forms
    </Link>
  </Item>   
</Menu>
);

export default AdminNav;
