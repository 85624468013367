import React from "react";
import Layout from "./Layout";
import Bottom from "../components/home/Bottom";
import TopPageHome from "../components/home/TopPage";
import Content from "../components/home/Content";
import TopPageWebnode from "../components/webnode/TopPage";
import NeedHelp from "../components/webnode/NedHelp";
import BottomPage from "../components/webnode/BottomPage";

const Home = () => {    
    

    return (
        <Layout
            title="WebNode Consulting"
            description="Consultant IT  |  Reprezentant Softeh"            
        >            
            <div className='main main-raised'>                
            <TopPageHome />
            <TopPageWebnode/>
            <Content />
            <NeedHelp />
            <BottomPage />
            <Bottom />
            </div>
        </Layout>
    );
};

export default Home;