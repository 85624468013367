import React from "react";
import { Select } from "antd";
import { SendOutlined  } from "@ant-design/icons";

const { Option } = Select;

const ContactForm = ({
    handleSubmit,
    handleChange,
    setValues,
    values,      
  }) => {
    
      
    return (
        <form onSubmit={handleSubmit}>
            <div className="container-fluid col-md-6 text-muted">
                <div className="row ">                                    
                    <div className="form-group">                        
                        <input
                        type="text"
                        name="name"
                        className="form-control"   
                        placeholder="Nume"  
                        onChange={handleChange}                                  
                        />
                    </div>                                                          
                    <div className="form-group">                        
                        <input
                        type="text"
                        name="email"
                        className="form-control" 
                        placeholder="Email"   
                        onChange={handleChange}                                  
                        />
                    </div>  
                    <div className="form-group">    
                    <label>Sunt interesat de:</label>        
                    <select     
                        type="text"    
                        name="services"
                        placeholder="Sunt interesat de"                        
                        className="form-control text-muted"                        
                        onChange={handleChange}
                    >   
                        <option >Alege </option>        
                        <option >Software farmacie </option>                        
                        <option >Asistenta tehnica </option> 
                        <option >Web Design </option>
                        <option >Web Development </option>
                    </select>
                    </div>  
                </div>         
            </div>
            <br />
            <p onClick={handleSubmit} className="button"><SendOutlined  /></p>                            
        </form>
    );
  };
  
  export default ContactForm;