import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Layout from "../../../core/Layout";
import { getTikets, getStatusValues, updateTiketStatus, getTiketProgress } from "../../../functions/tiket";
import { isAuthenticated } from "../../../components/auth";
import AdminNav from "../../../components/nav/AdminNav";
import { LoadingOutlined } from "@ant-design/icons";



const ProgressTikets = ({     
    linkAdmin = true
}) => {    
    const [statusValues, setStatusValues] = useState([]);
    const { user, token } = isAuthenticated();
    const [tikets, setTikets] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [company, setCompany] = useState();
    const [workstation, setWorkstation] = useState();
    const [error, setError] = useState();
    

     const loadTikets = () => {
        setLoading(true);
        getTiketProgress(user._id, token).then(t => {
            if (t.data.error) {
                setLoading(false);
            } else {          
                setLoading(false);      
                setTikets(t.data);
            }
        });
    };

    const loadStatusValues = () => {
        getStatusValues(user._id, token).then(data => {
            if (data.error) {
                //
            } else {
                setStatusValues(data);
            }
        });
    };

    useEffect(() => { 
        loadTikets();   
        loadStatusValues();      
    }, []);
    

    const handleStatusChange = (e, tiketId) => {
        updateTiketStatus(user._id, token, tiketId, e.target.value).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    loadTikets();
                }
            }
        );
    };

     const showStatus = o => (
        <div className="form-group">
            <h3 className="mark mb-4">Status: {o.status}</h3>
            <select
                className="form-control"
                onChange={e => handleStatusChange(e, o._id)}
            >
                <option>Update Status</option>
                {statusValues.map((status, index) => (
                    <option key={index} value={status}>
                        {status}
                    </option>
                ))}
            </select>
        </div>
    );     


    const showsLinkAdmin = linkAdmin => {
        return (
            linkAdmin && (                
            <div className="card-footer">                                   
                    {tikets.map((o, oIndex) => {
                        return (
                            <div key={oIndex} style={{ borderBottom: "5px solid black" }}>
                                <div className="mt-2">
                                    <span className="text-blue">
                                        Companie: {o.cname} | Punct de lucru: {o.wname} </span>                                  
                                </div>                                
                                <div className="mt-2">                                        
                                    {showStatus(o)}                                    
                                </div>                                     
                                <div className="card-footer d-flex justify-content-between">
                                    <p className="text-blue font-italic mb-0 pointer">
                                        Observatie: {o.observation}                                  
                                    </p>
                                </div>                                       
                                <div className="">
                                    <p className="text-danger font-italic mb-0">
                                        Nume: {o.name} | Telefon: {o.phone} |   Tiket ID: {o.tiketid}                                 
                                    </p>
                                </div> 
                                <div className="">
                                    <p className="text-upercase">
                                        Sesizare depusa in :{" "}
                                    {moment(o.createdAt).fromNow()}                                   
                                    </p>
                                </div>                                               
                            </div>
                        );
                    })}
               
            </div>
       
            )
        );
    };

       const goBack = () => (
        <div className="mt-5">
            <Link to="/admin/dashboard" className="text-warning">
                Back to Dashboard
            </Link>
        </div>
    );

    return (
        <Layout     
            description={`G'day ${isAuthenticated().user.name}! `}
        >  
            <div className="row" style={{ minHeight: '80vh' }}>
                <div className="col-md-3">
                    <AdminNav />
                </div>
                <div className="col-md-8">
                        {loading ? (
                            <LoadingOutlined className="text-danger h1" />
                            ) : (
                                <h4>Sesizari preluate</h4>
                            )}
                        <hr />
                    {showsLinkAdmin(linkAdmin)}
                </div>
            </div>
        </Layout>
        
    );
};



export default ProgressTikets;
