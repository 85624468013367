import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updatePassReset } from "../../functions/snvm";
import { toast } from "react-toastify";
import { isAuthenticated } from "../auth";
import { Card, Tooltip, Button } from "antd";
import { ReloadOutlined  } from "@ant-design/icons";

const { Meta } = Card

const SnvmCard = ({
    snvm,
    loadSnvms,  
    linkCard = true
}) => {    
    const { user, token } = isAuthenticated();
    const [success, setSuccess] = useState(false); 

    const handleReset = (slug) => {        
        updatePassReset(slug, user._id, token, snvm ).then(
            data => {
                if (data.error) {
                    toast.error(data.error);
                } else {
                    toast.success(`"${snvm.workstation}" is reseted time to 90 days`);
                    loadSnvms();
                    {showTime()};                   
                }
            }
        );
    }; 


    const showTime = resetpass => {
        let countDownDate = new Date(snvm.updatedAt).getTime();
        let now = new Date().getTime();
        let distance = now - countDownDate;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let resset = snvm.resetpass - days;
        return resset > 5 ? (
            <span className="badge badge-primary badge-pill">Reset LUF in {resset} zile</span>
        ) : (
            <span className="badge badge-danger badge-pill">Schimati parola! Mai aveti {resset} zile</span>
        );
    };

    const showsLinkCard = linkCard => {
        return (
            linkCard && (
                <Card
                    className="mt-4"
                    hoverable
                    title={`${snvm.company}`}
                    cover={
                        <img
                          src="/img/cafe.png"
                          style={{ height: "60px", width: "230px", objectFit: "cover" }}
                          
                        />
                      }
                      actions={[
                        <a onClick={() => handleReset(snvm.slug)}>
                        <ReloadOutlined  className="text-info" /> <br /> Reset timer
                        </a>,                                       
                    ]}
                >    
                <Meta
                        title={`${snvm.workstation}`}
                        description={showTime(snvm.resetpass)}  
                        className="text-center"                 
                />                         
        </Card>
            )
        );
    };

    return (
        <div className="mr-4">
            {showsLinkCard(linkCard)}            
        </div>
    );
};


export default SnvmCard;
