import React from "react";
import { Select } from "antd";

const { Option } = Select;

const CompanyCreateForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
   
}) => {
  // destructure
  const {
    title,
    description,
    cif,
    order,
    bank,
    bankaccount,
    adress,
    city,
    region,  
    email,  
  } = values;
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Nume Companie</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={title}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>CIF</label>
              <input
                type="text"
                name="cif"
                className="form-control"
                value={cif}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
            <label>Nr. Ordine</label>
            <input
              type="text"
              name="order"
              className="form-control"
              value={order}
              onChange={handleChange}
            />
          </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Banca</label>
              <input
                type="text"
                name="bank"
                className="form-control"
                value={bank}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Cont Bancar</label>
              <input
                type="text"
                name="bankaccount"
                className="form-control"
                value={bankaccount}
                onChange={handleChange}
              />
            </div>
          
            <div className="form-group">
            <label>Adresa Sediu</label>
            <input
              type="text"
              name="adress"
              className="form-control"
              value={adress}
              onChange={handleChange}
            />
          </div>   
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Oras</label>
              <input
                type="text"
                name="city"
                className="form-control"
                value={city}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Judet</label>
              <input
                type="text"
                name="region"
                className="form-control"
                value={region}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            name="email"
            className="form-control"
            value={email}
            onChange={handleChange}
          />
        </div>
          </div>           
        </div>
        <br />
        <button className="btn btn-outline-info">Save</button>
      </div>
    </form>
  );
};

export default CompanyCreateForm;
