import React, { useState, useEffect } from "react";
import Card from "../../../components/card/TiketUserCard";
import { isAuthenticated } from "../../../components/auth";
import { getTiket} from "../apiUser";
import { getTiketUserId } from "../../../functions/tiket";
import { getCompany } from "../../../functions/company";
import { getWorkstation } from "../../../functions/workstation";


const ActiveTiket = () => {        
    const { user, token } = isAuthenticated();
    const [error, setError] = useState(false);
    const [tikets, setTikets] = useState([]);
    const [company, setCompany] = useState();
    const [workstation, setWorkstation] = useState();
     

    const loadTiket = () => {   
        getTiketUserId(user._id, token, user).then(t => {
            if (t.data.error) {
                setError(t.data.error);
            } else {                
                setTikets(t.data);                          
            }
        });
    };     

    const loadCompany = () => {
        getCompany(user.company).then(c => {
            if (c.data.error) {
                setError(c.data.error);
            } else {               
                setCompany(c.data);                
            }
        });
    };

    const loadWorkstation = () => {
        getWorkstation(user.workstation).then(w => {
            if (w.data.error) {
                setError(w.data.error);
            } else {          
                setWorkstation(w.data.title);                
            }
        });
    };

    useEffect(() => {
        loadTiket();        
    }, []);

 
 
 return (
        <div >
            <div className="row">
                <div className="col-md-12 ">                    
            <div className="row d-flex justify-content-center">
                {tikets && tikets.map((tiket, i) => (
                    <div key={i} >
                        <Card tiket={tiket} company={company} workstation={workstation} />
                    </div>
                ))}
            </div>           
                  
                </div>
            </div>
        </div>
    );
};

export default ActiveTiket;