import React, { useState } from 'react';
import { Modal, Button } from 'antd';


const Privacy = () => {  
        const [isModalVisible, setIsModalVisible] = useState(false);
      
        const showModal = () => {
          setIsModalVisible(true);
        };
      
        const handleOk = () => {
          setIsModalVisible(false);
        };
      
        const handleCancel = () => {
          setIsModalVisible(false);
        };
      
        return (
          <>
            <a onClick={showModal} className="text-foot text-light">Politica de utilizare cookie</a>
            <Modal width={1000} title="Politica de utilizare cookie" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
             <div>                 
                 <p><span className="text-primary h6">Site-ul www.web-node.ro (“Site-ul”) foloseste cookies.</span> Informatiile prezentate in aceasta politica au drept scop
                  aducerea la cunostinta utilizatorilor a detaliilor privind folosirea de module cookies de catre Site. Ca rezultat al utilizarii acestor module cookies, administratorul 
                  Site-ului (WebNode) va primi informatii care ii va permite sa imbunatateasca experienta dumneavoastra in timpul navigarii, prin cresterea eficientei si 
                  accesibilitatii.</p>
                     <p>Un modul cookie reprezinta un text special, trimis de catre serverul nostru catre calculatorul dumneavoastra si trimis inapoi catre server de fiecare data 
                         cand accesati Site-ul. Modulele cookie faciliteaza recunoasterea terminalului de la care utilizatorul acceseaza site-ul sau subdomeniile sale, asigurand o 
                         prezentare relevanta a continutului, adaptata preferintelor anterioare ale utilizatorului. In general, modulele cookie stocheaza informatii cum ar fi 
                         preferintele anterioare de navigare, limba, localizarea geografica, preferintele de confidentialitate, etc. De asemenea, sunt folosite in pregatirea de 
                         statistici agregate anonimizate care faciliteaza intelegerea utilizatorului cu privire la experienta de pe site, permitand imbunatatirea structurii si a 
                         continutului, excluzand, in cele mai multe cazuri, identitatea personala a utilizatorului.</p>                     
                     <p><span className="text-primary h6 ">Durata de viata</span>
                     <ul>a unui cookie variaza in functie de scopul pentru care a fost introdus. De exemplu:
                        <li>o sesiune (sesiunea curenta) – se sterge la iesirea de pe Site;</li>
                        <li>cookie permanent – este stocat pe hard disk-ul computer-ului dumneavoastra si are de regula o perioada de stocare intre 1 si 2 ani.</li>                        
                    </ul></p>
                    <p><span className="text-primary h6 ">Cookie-urile sunt folosite</span>
                     <ul>
                        <li>in scopuri statistice si ne ajuta sa intelegem modalitatea in care utilizatorii interactioneaza cu site-ul prin colectarea si raportarea informatiilor in mod anonim;</li>
                        <li>in scop de marketing si urmaresc utilizatorii de pe alte site-uri. Intentia este de a afisa reclame care sunt relevante si captivante pentru utilizator, in mod individual 
                            si, prin urmare, mai importante pentru editori si agentii de publicitate terti.</li>                       
                    </ul></p>                    
                    <p>Tertii, precum Google, pot plasa la randul lor cookie-uri prin intermediul Site-ului, cu obligatia de a respecta legislatia cu privire la protectia datelor cu caracter personal. 
                        Tertii pot folosi cookie-urile pentru a promova activitatile de retail de produsele si servicii in scop de marketing direct, asupra carora nu detinem controlul. </p>
                    <p>Puteti sterge cookie-urile de pe hard drive oricand sau puteti evita cookie-urile prin configurarea browser-ului de a respinge sau a va notifica atunci cand un cookie este plasat pe hard drive-ul dumneavoastra.
                         Pentru mai multe informatii despre setarile cookie vizitati optiunea „help” a browser-ului.</p>
                    <p>Daca ati dezactivat utilizarea modulelor cookie, exista posibilitatea ca unele servicii ale Site-ului sa nu functioneze sau sa functioneze incorect</p>
                    <p><span className="text-primary h6 ">Date de contact</span><br /> Daca aveti orice intrebari, comentarii sau solicitari referitoare la aceasta Politica sau intentionati sa va exercitati drepturile anterior
                     mentionate, va rugam sa nu ezitati sa ne contactati la adresele mentionate mai jos.</p>
                     <em><span className="text-primary h6 ">Webnode Consulting SRL</span><br />Romania, Bihor, str. Unirii, nr. 14, ap. 1, webnode.consulting@gmail.com</em>
                </div>
            </Modal>
          </>
        );      
};

export default Privacy;