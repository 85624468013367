import axios from "axios";
import { API } from "../config";


export const createSNVM = async (snvm, userId, token) =>
  await axios.post(`${API}/snvm/create/${userId}`, snvm, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeSNVM = async (slug, userId, token) =>
  await axios.delete(`${API}/snvm/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getSNVM = async (slug) =>
  await axios.get(`${API}/snvm/${slug}`);

export const updateSNVM = async (slug, snvm, token) =>
  await axios.put(`${API}/snvm/${slug}`, snvm, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getSNVMs = async () =>
  await axios.get(`${API}/snvms`);

export const getRelated = async (snvmId) =>
  await axios.get(`${API}/snvm/related/${snvmId}`);

export const updatePassReset = async (slug, userId, token, snvm) =>
  await axios.put(`${API}/snvm/${slug}/reset/${userId}`, snvm, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

 