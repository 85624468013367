import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updatePassReset } from "../../functions/snvm";
import { toast } from "react-toastify";
import { isAuthenticated } from "../auth";
import { Card, Tooltip, Button } from "antd";
import { ReloadOutlined  } from "@ant-design/icons";

const { Meta } = Card

const TiketUserCard = ({
    tiket, 
    linkCard = true
}) => {    
    const { user, token } = isAuthenticated();    

   
    const showsLinkCard = linkCard => {
        return (
            linkCard && (
                <Card
                    hoverable    
                                   
                    title={`${tiket.status}`}                                
                >    
                <Meta
                        title={`${tiket.name} - ${tiket.cname}`}
                        description={`${tiket.wname} - ${tiket.phone}`}                         
                        className="text-center"                 
                /> 
                <br />
                <h5 className="text-danger text-center">Obs: {tiket.observation}</h5>     
                <p>Tiket ID: {tiket.tiketid}</p>                    
        </Card>
            )
        );
    };

    return (
        <div className="mr-4 py-2">
            {showsLinkCard(linkCard)}            
        </div>
    );
};


export default TiketUserCard;
