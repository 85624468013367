import React, { useState, useEffect } from "react";
import Layout from "../../../core/Layout";
import AdminNav from "../../../components/nav/AdminNav";
import SnvmCard from "../../../components/card/SnvmCard";
import { getSNVMs } from "../../../functions/snvm";
import { isAuthenticated } from "../../../components/auth";

const Snvm = () => {   
    const [snvms, setSnvms] = useState([]);
    const { user } = isAuthenticated();
    
    const loadSnvms = () =>
        getSNVMs().then((s) => setSnvms(s.data));

    useEffect(() => {
        loadSnvms();        
    }, []);
   
    return (
        <Layout            
        description={`Buna ziua ${user.name}, aici poti sa vizualizezi clientii OSMR.`}           
        >            
            <div className="">
                <div className="row " style={{ minHeight: '75vh' }}>
                    <div className="col-md-3">
                        <AdminNav />
                    </div>
                    <div className='col-md-9 '>
                        <h2 className="mb-4">SNVM Client Controls</h2>
                        <div className="row py-4 d-flex justify-content-center">
                            {snvms.map((snvm, i) => (
                                <div key={i} >
                                    <SnvmCard snvm={snvm} loadSnvms={loadSnvms} />
                                </div>
                            ))}
                        </div>            
                    </div>
                </div>
            </div>           
        </Layout>
    );
};

export default Snvm;
