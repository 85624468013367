import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SnvmClientCreateForm = ({
    handleSubmit,
    handleChange,
    handleCompanyChange,
    handleWorkstationChange,    
    showWork,
    values, 
    companys, 
    workstations       
  }) => {
    // destructure
    const { company, workstation, adminuser, lufuser, description, resetpass } = values;
      
    return (
        <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">             
              <div className="form-group">
                  <label>Company </label>
                  <select          
                    style={{ width: "100%" }}
                    className="btn btn-info h6 text-primary"                                      
                    onChange={handleCompanyChange}
                  > 
                  <option>Please select</option>       
                    {companys.length > 0 &&
                      companys.map((c) => (
                          <option key={c.title} value={c._id}>
                              {c.title}
                          </option>                        
                      ))}
                  </select>
                </div>      
                {showWork && (
                <div className="form-group">
                  <label>Workstation </label>
                  <select          
                    style={{ width: "100%" }}
                    className="btn btn-info h6 text-primary"                     
                    onChange={handleWorkstationChange}                                    
                  > 
                    <option>Please select</option>
                    {workstations.length > 0 &&
                      workstations.map((c) => (
                          <option key={c.title} value={c._id}>
                              {c.title}
                          </option>                        
                      ))}
                  </select>
                </div>  
                )}
                <div className="form-group">
                    <label>Reset day's</label>
                    <input
                    type="number"
                    name="resetpass"
                    className="form-control"
                    value={resetpass}
                    onChange={handleChange}
                    />
                </div>
            </div>

            <div className="col-md-6">
                <div className="form-group">
                    <label>Admin User</label>
                    <input
                    type="text"
                    name="adminuser"
                    className="form-control"
                    value={adminuser}
                    onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>LUF User</label>
                    <input
                    type="text"
                    name="lufuser"
                    className="form-control"
                    value={lufuser}
                    onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  value={description}
                  onChange={handleChange}
                />
              </div>
            </div>          
          </div>
          <br />
          <button className="btn btn-outline-info">Save</button>
        </div>
    </form>
    );
  };
  
  export default SnvmClientCreateForm;