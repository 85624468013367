import React, { useState } from "react";
import { toast } from "react-toastify";
import ContactForm from "../forms/ContactForm";
import { createContact } from "../../functions/contact";
import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
    name: "",     
    email: "",
    services: "", 
    contactId: `${new Date().getTime()}`    
  };

const BottomPage = () => {    
    const [values, setValues] = useState(initialState); 
    const [loading, setLoading] = useState(false);    

    const loadDefault = () => setValues({name: "", email: "", services: "", contactId: `${new Date().getTime()}`});

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        createContact(values)
          .then((res) => {
            setLoading(false);
            toast.success(`Cererea dumneavoastra a fost trimisa cu succes`);
            loadDefault();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response.data)
            if (err.response.status === 400) toast.error(err.response.data);
            //toast.error(err.response.data.err);
          });
      };
    
      const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        // console.log(e.target.name, " ----- ", e.target.value);
      };  
    
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4">            
                <div className="row d-flex justify-content-center" >
                    <div className="col-md-3 mt-4 text-center" >                       
                    <h4 className="text-dark">Solicita oferta!</h4>
                        <hr className="TextStyle"/>   
                        {loading ? (
                            <LoadingOutlined className="text-danger h1" />
                            ) : (
                                <ContactForm 
                                handleSubmit={handleSubmit}
                                handleChange={handleChange}
                                setValues={setValues}
                                values={values} />
                            )}
                    </div> 
                    <div className="col-md-6 text-center mt-4">  
                    <div className="row d-flex justify-content-center mt-4">  
                    <h4 className="text-dark text-center"></h4>
                        <hr className="TextStyle"/>                  
                        <div className="col-md-6 text-center" >            
                            <img className="card-img-top py-4 " src="/img/tel.png" alt="Card" style={{ width: "5rem" }}></img>
                            <p className="text-dark">TELEFON:<br />
                                <span className="text-muted">
                                    <span className="text-danger">(+40)</span> 727.69.26.63<br />
                                    <span className="text-danger ">(+40)</span> 770.39.08.26
                                </span>
                            </p>                        
                        </div>
                        <div className="col-md-6 text-center" >            
                            <img className="card-img-top py-4" src="/img/email.png" alt="Card" style={{ width: "5rem" }}></img>
                            <p className="text-dark">Email:<br />
                                <span className="text-muted ">webnode.consulting@gmail.com</span><br />                                
                            </p>                        
                        </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>        
    );
};

export default BottomPage;


