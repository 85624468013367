import React from "react";
import Header from "../components/nav/Header";
import "./Home.css";


const Layout = ({
    title = "Title",
    description = "Description",
    className,
    children
}) => (
    <div>
        <Header />        
        <div className="jumbotron shadow">
            <h2 className="text-light">{title}</h2>
            <p className="lead">{description}</p>           
        </div>
        <div className={className}>{children}</div>
        <blockquote  className="section text-center">
            <h6 className="blockquote-footer font-italic">© {(new Date().getFullYear())} WebNode Consulting, All Rights Reserved</h6>       
        </blockquote> 
    </div>
);

export default Layout;