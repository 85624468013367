import React, { useState, useEffect } from "react";
import axios from "axios";


const HeliumDash = () => { 
    const [status, setStatus] = useState([]);   
    const [hostpots, setHostpots] = useState([]); 

    useEffect(() => {
        getHostpot();
        //getHostpots();
      }, []);
    

    const getHostpot = async () => {
        const {data} = await axios.get(`https://api.helium.io/v1/hotspots/11BRxC36irRGijFSG7S8NV8R6Z6wzgtkzP2i59Q9GMb836qRj5B/activity?cursor=eyJtaW5fYmxvY2siOjk2MDk4NiwibWF4X2Jsb2NrIjo5NzU1MDYsImJsb2NrIjo5NzU1MDAsImFuY2hvcl9ibG9jayI6OTc1NTAwfQ`);
        setStatus(data.data);
        console.log(data);
    }

    const getHostpots = async () => {
        const {data} = await axios.get(`https://api.helium.io/v1/hotspots/location/distance?lat=46.53347733157257&lon=22.456733665669283&distance=5000`);
        setHostpots(data.data);
        console.log(data);
    }

    const pharmec = [
  
      "NBCICH-62107"
   ,
    
      "NBCJAJ-5700"
   ,
    
      "NBCJBJ-48588"
   ,
    
      "NBCJCJ-14012"
   ,
    
      "NBCJCL-4197"
   ,
    
      "NBCJCY-1550"
   ,
    
      "NBCICH-62184"
   ,
    
      "NBCICH-62464"
   ,
    
      "NBCJBL-11230"
   ,
    
      "NBCJCH-47558"
   ,
    
      "NBCJCK-23519"
   ,
    
      "NISBDF-28755"
   ,
    
      "NBCICH-62372"
   ,
    
      "NBCICH-62587"
   ,
    
      "NBCJCH-47696"
   ,
    
      "NBCJCH-47724"
   ,
    
      "NBCJCK-23411"
   ,
    
      "NBCJCL-4059"
   ,
    
      "NBCJAI-25792"
   ,
    
      "NBCJAJ-5899"
   ,
    
      "NBCJCH-47684"
   ,
    
      "NBCJCL-4062"
   ,
    
      "NBCJCY-1253"
   ,
    
      "NBCICH-62578"
   ,
    
      "NBCJCH-47682"
   ,
    
      "NBCJCK-23295"
   ,
    
      "NBCJCK-23504"
   ,
    
      "NBCJCL-4025"
   ,
    
      "NBCJAJ-55472"
   ,
    
      "NBCJBJ-48676"
   ,
    
      "NBCJCL-4032"
   ,
    
      "NBCJCY-1263"
   ,
    
      "NBCJCY-2099"
   ,
    
      "NBCICH-62105"
   ,
    
      "NBCJAJ-5747"
   ,
    
      "NBCJBJ-48589"
   ,
    
      "NNTJCD-36258"
   ,
    
      "NBCJAH-59487"
   ,
    
      "NBCJBJ-48683"
   ,
    
      "NBCJCJ-14054"
   ,
    
      "NBCJDH-1150"
   ,
    
      "NBCICH-62161"
   ,
    
      "NBCJAJ-5575"
   ,
    
      "NBCJCK-45352"
   ,
    
      "NBCJDK-3084"
   ,
    
      "NBCJAJ-5933"
   ,
    
      "NBCJCK-23538"
   ,
    
      "NBCJCK-45353"
   ,
    
      "NBCJCL-4088"
   ,
    
      "NBCJCK-23539"
   ,
    
      "NBCJCK-23558"
   ,
    
      "NBCJDK-19092"
   ,
    
      "NNTICB-34398"
   ,
    
      "NBCJBH-20190"
   ,
    
      "NBCJCK-45355"
   ,
    
      "NBCJDH-1118"
   ,
    
      "NBCJDK-3006"
   ,
    
      "NBCICH-62243"
   ,
    
      "NBCJBL-11386"
   ,
    
      "NBCJCH-47774"
   ,
    
      "NBCJCL-4040"
   ,
    
      "NBCICH-62303"
   ,
    
      "NBCJCJ-13900"
   ,
    
      "NBCJCK-45356"
   ,
    
      "NBCJDK-3233"
   ,
    
      "NBCICH-62227"
   ,
    
      "NBCJCL-4134"
   ,
    
      "NNTGBD-243273"
   ,
    
      "NBCICH-62238"
   ,
    
      "NBCJCJ-14023"
   ,
    
      "NBCJDK-3039"
   ,
    
      "NBCICH-62285"
   ,
    
      "NBCJBL-10825"
   ,
    
      "NBCJCK-23520"
   ,
    
      "NBCICH-62290"
   ,
    
      "NBCJBL-11114"
   ,
    
      "NBCJCL-4137"
   ,
    
      "NBCICH-62088"
   ,
    
      "NBCJBJ-48747"
   ,
    
      "NBCJBL-11388"
   ,
    
      "NBCICH-62381"
   ,
    
      "NBCJBL-11115"
   ,
    
      "NBCJDK-3024"
   ,
    
      "NBCJCJ-14035"
   ,
    
      "NBCJCJ-14164"
   ,
    
      "NBCJDK-3018"
   ,
    
      "NBCJAH-24057"
   ,
    
      "NBCJCJ-14188"
   ,
    
      "NBCJCK-45362"
   ,
    
      "NBCJAI-26128"
   ,
    
      "NBCJCJ-14245"
   ,
    
      "NBCJCK-45327"
   ,
    
      "NBCJAH-59493"
   ,
    
      "NBCJCY-1962"
   ,
    
      "NBCJDK-3172"
   ,
    
      "NBCICH-62470"
   ,
    
      "NBCJAJ-5714"
   ,
    
      "NBCJCH-47597"
   ,
    
      "NBCICH-62521"
   ,
    
      "NBCJAH-59495"
   ,
    
      "NBCJDK-3178"
   ,
    
      "NBCJAH-59494"
   ,
    
      "NBCJCK-23312"
   ,
    
      "NBCJCY-2033"
   ,
    
      "NBCJDK-3213"
   ,
    
      "NNTJCB-22046"
   ,
    
      "NBCJBJ-48861"
   ,
    
      "NBCJCL-4051"
   ,
    
      "NBCJBL-11231"
   ,
    
      "NBCJDK-3235"
   ,
    
      "NBCJCK-23252"
   ,
    
      "NBCJCL-4256"
   ,
    
      "NBCJCH-47675"
   ,
    
      "NBCJCL-4314"
   ,
    
      "NBCJCK-45712"
   ,
    
      "NBCJCL-4058"
   ,
    
      "NBCJCL-4057"
   ,
    
      "NBCIAH-91891"
   ,
    
      "NBCIAH-91890"
   ,
    
      "NBCICH-62170"
   ,
    
      "NBCJCK-45380"
   ,
    
      "NBCJCJ-14025"
   ,
    
      "NBCJCL-4060"
   ,
    
      "NBCJCL-4061"
   ,
    
      "NBCIDH-11086"
   ,
    
      "NBCJCJ-14051"
   ,
    
      "NBCIBG-2139"
   ,
    
      "NBCJCK-23541"
   ,
    
      "NBCJCK-45396"
   ,
    
      "NBCJCK-45395"
   ,
    
      "NBCJCK-23534"
   ,
    
      "NBCJCK-23536"
   ,
    
      "NBCJCH-47674"
   ,
    
      "NBCJCJ-13966"
   ,
    
      "NBCICH-62166"
   ,
    
      "NBCICH-62181"
   ,
    
      "NBCIAH-91892"
   ,
    
      "NBCJCK-23505"
   ,
    
      "NBCJCK-23506"
   ,
    
      "NBCJCH-47735"
   ,
    
      "NBCJCH-47737"
   ,
    
      "NBCJCL-4026"
   ,
    
      "NBCJCL-4027"
   ,
    
      "NBCJAH-59496"
   ,
    
      "NBCJAJ-5621"
   ,
    
      "NISHDK-69108"
   ,
    
      "NBCJCH-47555"
   ,
    
      "NBCJBJ-48677"
   ,
    
      "NBCJCK-45369"
   ,
    
      "NBCJBJ-48678"
   ,
    
      "NBCJCK-45394"
   ,
    
      "NBCIAE-6799"
   ,
    
      "NBCICH-62087"
   ,
    
      "NNTJCD-36257"
   ,
    
      "NBCJAJ-5691"
   ,
    
      "NBCJAJ-5692"
   ,
    
      "NBCJCL-4019"
   ,
    
      "NBCJBL-11174"
   ,
    
      "NBCJBJ-48681"
   ,
    
      "NBCJBJ-48682"
   ,
    
      "NBCJCH-47736"
   ,
    
      "NBCJAJ-5576"
   ,
    
      "NBCJCJ-14013"
   ,
    
      "NBCJCH-50623"
   ,
    
      "NBCJAJ-5724"
   ,
    
      "NBCJAJ-5729"
   ,
    
      "NBCJCJ-14053"
   ,
    
      "NBCJCJ-14052"
   ,
    
      "NBCJCJ-14061"
   ,
    
      "NBCJBH-56288"
   ,
    
      "NBCICH-62195"
   ,
    
      "NBCICH-62193"
   ,
    
      "NBCJCH-47760"
   ,
    
      "NBCJAJ-5734"
   ,
    
      "NBCJCH-47734"
   ,
    
      "NBCJAJ-5733"
   ,
    
      "NBCJCL-4085"
   ,
    
      "NBCJAJ-5737"
   ,
    
      "NBCJCJ-14070"
   ,
    
      "NBCJCJ-14067"
   ,
    
      "NBCJCK-23494"
   ,
    
      "NBCJCJ-14068"
   ,
    
      "NBCJCL-4084"
   ,
    
      "NBCJCH-47777"
   ,
    
      "NBCJCJ-14071"
   ,
    
      "NBCJCK-23141"
   ,
    
      "NBCJAJ-5728"
   ,
    
      "NBCIAH-91838"
   ,
    
      "NBCJCK-45407"
   ,
    
      "NBCJCK-45406"
   ,
    
      "NBCJCK-23564"
   ,
    
      "NBCJAH-59506"
   ,
    
      "NBCJCK-23563"
   ,
    
      "NBCJAH-59507"
   ,
    
      "NBCJCL-4064"
   ,
    
      "NBCJAJ-5739"
   ,
    
      "NBCJCK-45410"
   ,
    
      "NBCJCK-45412"
   ,
    
      "NBCJCK-45411"
   ,
    
      "NBCJCL-4110"
   ,
    
      "NBCJCH-47791"
   ,
    
      "NBCJCL-4030"
   ,
    
      "NBCICH-62219"
   ,
    
      "NBCICH-62220"
   ,
    
      "NBCJAH-59511"
   ,
    
      "NNTJCD-25414"
   ,
    
      "NNTJCD-25399"
   ,
    
      "NBCJCK-45430"
   ,
    
      "NBCJCK-45428"
   ,
    
      "NBCJCK-45427"
   ,
    
      "NBCJCK-45426"
   ,
    
      "NBCJCL-4078"
   ,
    
      "NBCJCL-4079"
   ,
    
      "NBCICH-62233"
   ,
    
      "NBCICH-62235"
   ,
    
      "NBCJCJ-13632"
   ,
    
      "NBCICH-62231"
   ,
    
      "NBCICH-62237"
   ,
    
      "NBCJCK-45435"
   ,
    
      "NBCIAH-91914"
   ,
    
      "NBCJAH-59523"
   ,
    
      "NBCJAH-59530"
   ,
    
      "NBCJCK-31506"
   ,
    
      "NBCICH-62218"
   ,
    
      "NBCJCH-55267"
   ,
    
      "NBCJCH-47370"
   ,
    
      "NBCJCH-47794"
   ,
    
      "NBCJCJ-14075"
   ,
    
      "NBCJCK-23597"
   ,
    
      "NBCJCJ-14076"
   ,
    
      "NBCJCJ-14090"
   ,
    
      "NBCJCK-23596"
   ,
    
      "NBCJCL-4098"
   ,
    
      "NBCJCK-45404"
   ,
    
      "NBCJCL-4097"
   ,
    
      "NBCJCK-45405"
   ,
    
      "NBCJCK-23537"
   ,
    
      "NBCJAJ-5766"
   ,
    
      "NBCJCL-4124"
   ,
    
      "NBCJCL-4123"
   ,
    
      "NBCICH-62255"
   ,
    
      "NBCIDH-11089"
   ,
    
      "NBCICH-62257"
   ,
    
      "NBCICH-62258"
   ,
    
      "NBCJCK-45447"
   ,
    
      "NBCJAH-59524"
   ,
    
      "NBCICH-62260"
   ,
    
      "NNTJCB-22093"
   ,
    
      "NBCICH-62265"
   ,
    
      "NNTJCB-22092"
   ,
    
      "NBCJCJ-13981"
   ,
    
      "NBCHCK-61593"
   ,
    
      "NBCICH-62267"
   ,
    
      "NBCJDK-3007"
   ,
    
      "NBCJCJ-14058"
   ,
    
      "NBCJAJ-5772"
   ,
    
      "NBCJAJ-5771"
   ,
    
      "NBCJCJ-14102"
   ,
    
      "NBCJCJ-13835"
   ,
    
      "NBCJCL-4133"
   ,
    
      "NBCIAH-91930"
   ,
    
      "NBCJAH-59548"
   ,
    
      "NBCICH-62271"
   ,
    
      "NBCJAJ-5269"
   ,
    
      "NBCICH-62276"
   ,
    
      "NBCJCK-25274"
   ,
    
      "NBCJCK-45471"
   ,
    
      "NBCJCK-45461"
   ,
    
      "NBCJCK-45462"
   ,
    
      "NBCICH-62159"
   ,
    
      "NBCICH-62287"
   ,
    
      "NBCJDK-3010"
   ,
    
      "NBCJDH-1122"
   ,
    
      "NBCICH-62291"
   ,
    
      "NBCJDH-1136"
   ,
    
      "NBCJCK-45475"
   ,
    
      "NBCJCK-45477"
   ,
    
      "NBCJCK-45485"
   ,
    
      "NNTJCD-56332"
   ,
    
      "NBCJCK-45487"
   ,
    
      "NBCJCK-45489"
   ,
    
      "NBCJCL-4136"
   ,
    
      "NBCJCH-47559"
   ,
    
      "NBCIAH-91931"
   ,
    
      "NBCJCK-45496"
   ,
    
      "NBCJCK-45498"
   ,
    
      "NBCJAB-3440"
   ,
    
      "NBCJAB-3443"
   ,
    
      "NBCJCK-45422"
   ,
    
      "NBCJCJ-14100"
   ,
    
      "NBCJAJ-5774"
   ,
    
      "NBCJCK-45506"
   ,
    
      "NBCJCK-45508"
   ,
    
      "NBCJCK-45505"
   ,
    
      "NBCJCK-45504"
   ,
    
      "NBCJAJ-5743"
   ,
    
      "NBCIAH-91932"
   ,
    
      "NBCJCH-47775"
   ,
    
      "NNTJBI-2757"
   ,
    
      "NBCJDK-3030"
   ,
    
      "NBCJDK-3029"
   ,
    
      "NBCJCJ-14115"
   ,
    
      "NNTJCB-22110"
   ,
    
      "NBCJDK-3028"
   ,
    
      "NBCJCJ-14117"
   ,
    
      "NBCJCJ-14118"
   ,
    
      "NBCJCJ-14123"
   ,
    
      "NBCJAH-59559"
   ,
    
      "NBCJCK-45517"
   ,
    
      "NBCICH-62304"
   ,
    
      "NBCJCJ-14133"
   ,
    
      "NBCICH-62324"
   ,
    
      "NBCJCJ-13899"
   ,
    
      "NBCICH-62312"
   ,
    
      "NBCICH-62320"
   ,
    
      "NBCJCL-4135"
   ,
    
      "NBCJCJ-14134"
   ,
    
      "NBCJDK-3048"
   ,
    
      "NBCJDK-3047"
   ,
    
      "NBCIAH-91792"
   ,
    
      "NBCICH-62331"
   ,
    
      "NBCJCJ-14087"
   ,
    
      "NBCJDH-1144"
   ,
    
      "NBCICH-62311"
   ,
    
      "NBCJAJ-5794"
   ,
    
      "NBCJCK-45530"
   ,
    
      "NBCJCK-45532"
   ,
    
      "NBCJDK-3052"
   ,
    
      "NBCIAH-91950"
   ,
    
      "NBCJDK-3054"
   ,
    
      "NBCJDK-3038"
   ,
    
      "NNTJCB-22095"
   ,
    
      "NBCJBH-56435"
   ,
    
      "NBCJAH-59525"
   ,
    
      "NBCJDK-3040"
   ,
    
      "NBCJDH-1161"
   ,
    
      "NBCJDH-1160"
   ,
    
      "NBCJDH-1159"
   ,
    
      "NBCJAJ-5799"
   ,
    
      "NBCJAJ-5800"
   ,
    
      "NBCJCJ-14003"
   ,
    
      "NBCICH-62247"
   ,
    
      "NBCJAJ-5808"
   ,
    
      "NBCJCK-45551"
   ,
    
      "NBCJCK-45552"
   ,
    
      "NBCJCK-45553"
   ,
    
      "NBCJCK-45555"
   ,
    
      "NBCJCK-45556"
   ,
    
      "NBCJDH-1186"
   ,
    
      "NBCIAH-91946"
   ,
    
      "NBCICH-62343"
   ,
    
      "NBCJCL-4154"
   ,
    
      "NBCJAJ-5659"
   ,
    
      "NBCJAH-59566"
   ,
    
      "NBCJAH-59569"
   ,
    
      "NBCJCL-4155"
   ,
    
      "NBCICH-62346"
   ,
    
      "NBCJCJ-13929"
   ,
    
      "NBCJCK-23584"
   ,
    
      "NBCJCK-45568"
   ,
    
      "NBCJCK-45567"
   ,
    
      "NBCJCK-45569"
   ,
    
      "NBCJCK-45574"
   ,
    
      "NBCJAH-59576"
   ,
    
      "NBCJCK-45570"
   ,
    
      "NBCICH-62357"
   ,
    
      "NBCICH-62315"
   ,
    
      "NBCJCK-45573"
   ,
    
      "NBCJCK-45571"
   ,
    
      "NBCJCK-45572"
   ,
    
      "NBCIAH-91924"
   ,
    
      "NBCIAH-91923"
   ,
    
      "NBCJDK-3042"
   ,
    
      "NBCJDH-1165"
   ,
    
      "NBCJBL-11027"
   ,
    
      "NBCJCL-4152"
   ,
    
      "NBCJCJ-13514"
   ,
    
      "NBCJCL-4138"
   ,
    
      "NBCICH-62328"
   ,
    
      "NBCJAJ-5783"
   ,
    
      "NBCIAH-91706"
   ,
    
      "NBCICH-62306"
   ,
    
      "NBCJBJ-48748"
   ,
    
      "NBCJCK-45470"
   ,
    
      "NBCJCH-47626"
   ,
    
      "NNTJBH-11717"
   ,
    
      "NBCJCL-4166"
   ,
    
      "NBCJDH-1205"
   ,
    
      "NBCJDH-1206"
   ,
    
      "NBCJCL-4165"
   ,
    
      "NBCJAJ-5782"
   ,
    
      "NBCJAJ-5781"
   ,
    
      "NBCICH-62373"
   ,
    
      "NBCICH-62374"
   ,
    
      "NBCJDH-1203"
   ,
    
      "NBCICH-62377"
   ,
    
      "NBCJAH-59587"
   ,
    
      "NBCJCL-4184"
   ,
    
      "NBCJCL-4185"
   ,
    
      "NBCIAH-91964"
   ,
    
      "NBCJCL-4180"
   ,
    
      "NBCJCL-4181"
   ,
    
      "NBCJCJ-14152"
   ,
    
      "NBCJCJ-14151"
   ,
    
      "NBCJDK-3069"
   ,
    
      "NBCJDK-3070"
   ,
    
      "NBCJCK-25136"
   ,
    
      "NBCJAJ-5797"
   ,
    
      "NBCJAJ-5842"
   ,
    
      "NBCICH-62390"
   ,
    
      "NBCJCJ-14159"
   ,
    
      "NBCJAJ-5845"
   ,
    
      "NBCJDH-1215"
   ,
    
      "NBCJCL-4187"
   ,
    
      "NBCJCK-45557"
   ,
    
      "NBCJCL-4188"
   ,
    
      "NBCJCL-4191"
   ,
    
      "NBCICH-62395"
   ,
    
      "NBCJDK-3001"
   ,
    
      "NBCJDH-1236"
   ,
    
      "NBCJAJ-5779"
   ,
    
      "NBCIAH-91962"
   ,
    
      "NBCICH-62401"
   ,
    
      "NBCICH-62411"
   ,
    
      "NBCICH-62412"
   ,
    
      "NBCJDH-1222"
   ,
    
      "NBCJCJ-14186"
   ,
    
      "NBCJDK-3087"
   ,
    
      "NBCJDK-3086"
   ,
    
      "NBCJAJ-5818"
   ,
    
      "NBCJAJ-5817"
   ,
    
      "NBCJDH-1151"
   ,
    
      "NBCJDK-3096"
   ,
    
      "NBCJDK-3083"
   ,
    
      "NBCJCK-45431"
   ,
    
      "NBCJBL-5756"
   ,
    
      "NBCJCK-45620"
   ,
    
      "NBCICH-62375"
   ,
    
      "NBCJDK-3095"
   ,
    
      "NBCJAJ-5421"
   ,
    
      "NBCJDH-1149"
   ,
    
      "NBCJDH-1219"
   ,
    
      "NBCIAH-91961"
   ,
    
      "NBCICH-62417"
   ,
    
      "NBCJAJ-5851"
   ,
    
      "NBCJCJ-14120"
   ,
    
      "NBCJCJ-14119"
   ,
    
      "NBCICH-62418"
   ,
    
      "NBCJAJ-5858"
   ,
    
      "NBCJAH-59586"
   ,
    
      "NBCJCK-45667"
   ,
    
      "NBCJAJ-5761"
   ,
    
      "NBCICH-62382"
   ,
    
      "NBCJDH-1244"
   ,
    
      "NBCJCK-45676"
   ,
    
      "NBCJCK-45674"
   ,
    
      "NBCJAJ-5873"
   ,
    
      "NBCJAJ-5872"
   ,
    
      "NBCJCJ-14217"
   ,
    
      "NBCJCJ-14218"
   ,
    
      "NBCJDK-3133"
   ,
    
      "NBCJDK-3132"
   ,
    
      "NBCJDK-3134"
   ,
    
      "NBCICH-62447"
   ,
    
      "NBCJCK-45693"
   ,
    
      "NBCICH-62448"
   ,
    
      "NBCJCK-45691"
   ,
    
      "NBCJCK-45690"
   ,
    
      "NBCJDK-3017"
   ,
    
      "NBCJCH-47723"
   ,
    
      "NBCJCH-57726"
   ,
    
      "NBCJDH-1260"
   ,
    
      "NBCJCK-45709"
   ,
    
      "NBCJDH-1284"
   ,
    
      "NBCJAH-59596"
   ,
    
      "NBCJDK-3148"
   ,
    
      "NBCICH-62465"
   ,
    
      "NBCJAJ-5786"
   ,
    
      "NBCJAJ-5787"
   ,
    
      "NBCJAJ-5785"
   ,
    
      "NBCICH-62469"
   ,
        
      "NBCJCL-4042"
   ,
    
      "NBCIDH-11091"
   ,
    
      "NBCJCH-47170"
   ,
    
      "NBCJCL-4043"
   ,
    
      "NBCJDK-3143"
   ,
    
      "NBCICH-62454"
   ,
    
      "NBCJCJ-14104"
   ,
    
      "NBCJDH-1272"
   ,
    
      "NBCJDH-1271"
   ,
    
      "NBCICH-62468"
   ,
    
      "NBCICH-62408"
   ,
    
      "NBCJCL-4216"
   ,
    
      "NBCICH-62280"
   ,
    
      "NBCJCB-15134"
   ,
    
      "NBCJAJ-5623"
   ,
    
      "NBCICH-62472"
   ,
    
      "NBCIAH-91987"
   ,
    
      "NBCJCK-23590"
   ,
    
      "NBCJDK-3022"
   ,
    
      "NBCJDK-3023"
   ,
    
      "NBCJCJ-14165"
   ,
    
      "NBCJCJ-14163"
   ,
    
      "NBCJCJ-14182"
   ,
    
      "NBCJCJ-14166"
   ,
    
      "NBCJCH-47658"
   ,
    
      "NBCIAH-91986"
   ,
    
      "NBCJBH-56711"
   ,
    
      "NBCJCK-23493"
   ,
    
      "NBCJCK-23535"
   ,
    
      "NBCJCK-45682"
   ,
    
      "NBCJAJ-5868"
   ,
    
      "NBCJAH-24058"
   ,
    
      "NBCJCJ-14190"
   ,
    
      "NBCJAJ-5820"
   ,
    
      "NBCJCJ-14187"
   ,
    
      "NBCJCJ-14189"
   ,
    
      "NBCJCK-45701"
   ,
    
      "NBCJCK-45700"
   ,
    
      "NBCJBL-10993"
   ,
    
      "NBCJDH-1293"
   ,
    
      "NBCJDH-1294"
   ,
    
      "NBCJCL-4226"
   ,
    
      "NBCICH-62478"
   ,
    
      "NNTGBD-243306"
   ,
    
      "NBCJCJ-14239"
   ,
    
      "NBCJAJ-5890"
   ,
    
      "NBCJCK-23406"
   ,
    
      "NBCJCJ-14246"
   ,
    
      "NBCJCH-47701"
   ,
    
      "NBCJCK-45751"
   ,
    
      "NBCICH-62486"
   ,
    
      "NBCJCK-45757"
   ,
    
      "NBCJCL-4238"
   ,
    
      "NBCJAJ-5746"
   ,
    
      "NBCJAJ-5748"
   ,
    
      "NNTGBD-111680"
   ,
    
      "NBCJDK-3173"
   ,
    
      "NBCJCJ-14257"
   ,
    
      "NBCJBJ-48815"
   ,
    
      "NBCJCL-4245"
   ,
    
      "NBCJDH-1325"
   ,
    
      "NBCJCL-4244"
   ,
    
      "NBCICH-62481"
   ,
    
      "NBCJCK-23425"
   ,
    
      "NBCICH-62492"
   ,
    
      "NBCJDH-1349"
   ,
    
      "NBCICH-62497"
   ,
    
      "NBCJCL-4252"
   ,
    
      "NBCJCJ-14259"
   ,
    
      "NBCJCK-45793"
   ,
    
      "NBCJCK-45795"
   ,
    
      "NBCJCK-45794"
   ,
    
      "NBCJCK-45796"
   ,
    
      "NBCJCK-45792"
   ,
    
      "NBCJCK-45800"
   ,
    
      "NBCJDK-19001"
   ,
    
      "NBCICH-62507"
   ,
    
      "NBCJCK-45745"
   ,
    
      "NBCICH-62503"
   ,
    
      "NBCICH-62513"
   ,
    
      "NBCICH-62516"
   ,
    
      "NBCJDH-1340"
   ,
    
      "NBCJAH-59638"
   ,
    
      "NBCJCL-4198"
   ,
    
      "NBCJAH-59632"
   ,
    
      "NBCJDK-3175"
   ,
    
      "NISCDH-037666"
   ,
    
      "NBCICH-62490"
   ,
    
      "NBCICH-62488"
   ,
    
      "NBCJAH-59640"
   ,
    
      "NBCJCJ-14123"
   ,
    
      "NBCJCK-23408"
   ,
    
      "NBCJCK-23410"
   ,
    
      "NBCJAJ-5900"
   ,
    
      "NBCJAH-59631"
   ,
    
      "NBCJAJ-5880"
   ,
    
      "NBCJDK-3192"
   ,
    
      "NBCJCJ-14230"
   ,
    
      "NBCICH-62432"
   ,
    
      "NBCJAJ-5879"
   ,
    
      "NBCJCJ-13998"
   ,
    
      "NBCICH-62532"
   ,
    
      "NBCJAJ-5914"
   ,
    
      "NBCJDH-1357"
   ,
    
      "NBCJCJ-14269"
   ,
  
      "NBCJCK-23311"
   ,
    
      "NBCJBL-11384"
   ,
    
      "NBCJAH-59654"
   ,
    
      "NBCJAJ-5692"
   ,
    
      "NBCICH-62556"
   ,
    
      "NBCJAH-59663"
   ,
    
      "NBCJDH-1364"
   ,
    
      "NBCJDK-19027"
   ,
    
      "NBCICH-62560"
   ,
    
      "NBCJCL-4273"
   ,
    
      "NBCJBJ-48837"
   ,
    
      "NBCJDK-19041"
   ,
    
      "NBCJCJ-14280"
   ,
    
      "NBCJAJ-5878"
   ,
    
      "NBCHCK-61644"
   ,
    
      "NBCJCJ-14285"
   ,
    
      "NBCJCL-4274"
   ,
    
      "NBCJCJ-14284"
   ,
    
      "NBCJCJ-14283"
   ,
    
      "NNTIAJ-16148"
   ,
    
      "NNTIAJ-16147"
   ,
    
      "NBCICH-62573"
   ,
    
      "NBCICH-62571"
   ,
    
      "NBCJDK-19051"
   ,
    
      "NBCICH-62580"
   ,
    
      "NBCJDK-19064"
   ,
    
      "NBCICH-62572"
   ,
    
      "NBCJDK-19065"
   ,
       
      "NBCJDK-19060"
   ,
    
      "NBCJDK-19061"
   ,
    
      "NBCJDK-19063"
   ,
    
      "NBCJDK-19062"
   ,
    
      "NBCICH-62575"
   ,
    
      "NBCJDK-19058"
   ,
    
      "NBCJCL-4277"
   ,
    
      "NBCJCL-4289"
   ,
    
      "NBCICH-62579"
   ,
    
      "NNTIAJ-16136"
   ,
    
      "NBCJCJ-14259"
   ,
    
      "NBCJAH-59672"
   ,
    
      "NNTIAJ-16138"
   ,
    
      "NBCJDH-1392"
   ,
    
      "NBCJAH-59661"
   ,
    
      "NBCJAH-59675"
   ,
    
      "NBCICH-62546"
   ,
    
      "NBCJCJ-14021"
   ,
    
      "NBCJAJ-5925"
   ,
    
      "NBCJAJ-5927"
   ,
    
      "NBCJCJ-14022"
   ,
    
      "NBCJAJ-5926"
   ,
    
      "NBCICH-62585"
   ,
    
      "NBCJCL-4104"
   ,
    
      "NBCJCL-4145"
   ,
    
      "NBCJCK-23382"
   ,
    
      "NBCJAJ-5838"
   ,
    
      "NBCICH-62480"
   ,
    
      "NBCICH-62582"
   ,
    
      "NBCJAH-59660"
   ,
    
      "NBCJAH-59659"
   ,
    
      "NBCJDK-3046"
   ,
    
      "NBCJCJ-14290"
   ,
    
      "NBCJCK-45354"
   ,
    
      "NBCJDH-1305"
   ,
    
      "NBCJCJ-14233"
   ,
    
      "NBCJCJ-14232"
   ,
    
      "NNTIAK-21332"
   ,
    
      "NBCICH-62590"
   ,
    
      "NBCJBJ-48862"
   ,
    
      "NBCICH-62577"
   ,
    
      "NNTGBD-243343"
   ,
    
      "NBCJCL-4301"
   ,
    
      "NBCJAJ-5934"
   ,
    
      "NBCJDH-1408"
   ,
    
      "NBCJCL-4306"
   ,
    
      "NBCJDK-3116"
   ,
    
      "NBCICH-62593"
   ,
    
      "NNTJCB-22260"
   ,
    
      "NBCJDK-3236"
   ,
    
      "NBCJDJ-8407"
   ,
    
      "NBCJDK-3237"
   ,
    
      "NBCIAH-91988"
   ,
    
      "NNTJCD-56540"
   ,
    
      "NBCICH-62526"
   ,
    
      "NBCICH-62524"
   ,
    
      "NBCJAJ-5932"
   ,
    
      "NBCICH-62603"
   ,
    
      "NBCJDJ-8420"
   ,
    
      "NBCJAJ-5941"
   ,
    
      "NBCJAJ-5912"
   ,
    
      "NBCICH-62612"
   ,
    
      "NBCICH-62597"
   ,
    
      "NBCICH-62604"
   ,
    
      "NBCJCL-4310"
   ,
    
      "NBCJDK-19096"
   ,
    
      "NBCJDK-19094"
   ,
    
      "NBCJDK-19093"
   ,
    
      "NBCJCL-4255"
   ,
    
      "NBCIAH-92012"
   ,
    
      "NBCJDJ-8428"
   ,
    
      "NBCJAJ-5952"
   ,
    
      "NBCJDJ-8427"
   ,
    
      "NBCJAJ-5928"
   ,
    
      "NBCICH-62614"
   ,
    
      "NBCJDK-19105"
   ,
    
      "NBCJDK-19107"
   ,
    
      "NBCJDK-19050"
   ,
    
      "NBCIAH-92004"
   ,
    
      "NBCJCL-4336"
   ,
    
      "NBCICH-62190"
   ,
    
      "NBCJDK-19118"
   
   ,
    
      "NBCJBH-21109"
   ,
    
      "NBCJDK-19109"
   ,
    
      "NBCJCK-45710"
   ,
    
      "NBCJCK-45713"
   ,
    
      "NBCJCK-45711"
   ,
    
      "NBCIAH-91998"
  
   ,
    
      "NBCICH-62621"
   ,
    
      "NBCJDK-19123"
   ,
    
      "NBCJDK-19122"
   ,
    
      "NBCJCK-23583"
   ,
    
      "NBCJCK-23582"
   ,
    
      "NBCJCK-23183"
   ,
    
      "NBCJCJ-10499"
   ,
    
      "NBCJAH-59676"
   ,
    
      "NBCJCJ-14083"
   
  ]
    const availables = [
      {
        "nr": 47737
      },
      {
        "nr": 23519
      },
      {
        "nr": 4025
      },
      {
        "nr": 45352
      },
      {
        "nr": 45380
      },
      {
        "nr": 62181
      },
      {
        "nr": 23504
      },
      {
        "nr": 62184
      },
      {
        "nr": 1550
      },
      {
        "nr": 11231
      },
      {
        "nr": 59495
      },
      {
        "nr": 59487
      },
      {
        "nr": 4026
      },
      {
        "nr": 47682
      },
      {
        "nr": 4058
      },
      {
        "nr": 4059
      },
      {
        "nr": 45362
      },
      {
        "nr": 59493
      },
      {
        "nr": 13966
      },
      {
        "nr": 45396
      },
      {
        "nr": 2139
      },
      {
        "nr": 45355
      },
      {
        "nr": 4062
      },
      {
        "nr": 91892
      },
      {
        "nr": 48589
      },
      {
        "nr": 47696
      },
      {
        "nr": 11230
      },
      {
        "nr": 1263
      },
      {
        "nr": 4061
      },
      {
        "nr": 5700
      },
      {
        "nr": 4057
      },
      {
        "nr": 47674
      },
      {
        "nr": 11388
      },
      {
        "nr": 62166
      },
      {
        "nr": 23520
      },
      {
        "nr": 14035
      },
      {
        "nr": 14023
      },
      {
        "nr": 23536
      },
      {
        "nr": 61936
      },
      {
        "nr": 11115
      },
      {
        "nr": 11086
      },
      {
        "nr": 47675
      },
      {
        "nr": 23252
      },
      {
        "nr": 243273
      },
      {
        "nr": 45356
      },
      {
        "nr": 23505
      },
      {
        "nr": 62170
      },
      {
        "nr": 22046
      },
      {
        "nr": 23534
      },
      {
        "nr": 4032
      },
      {
        "nr": 59496
      },
      {
        "nr": 34398
      },
      {
        "nr": 23506
      },
      {
        "nr": 4060
      },
      {
        "nr": 45353
      },
      {
        "nr": 11114
      },
      {
        "nr": 47684
      },
      {
        "nr": 4027
      },
      {
        "nr": 1253
      },
      {
        "nr": 62105
      },
      {
        "nr": 47735
      },
      {
        "nr": 14025
      },
      {
        "nr": 4051
      },
      {
        "nr": 23541
      },
      {
        "nr": 14051
      },
      {
        "nr": 45395
      },
      {
        "nr": 59494
      },
      {
        "nr": 48588
      },
      {
        "nr": 45327
      },
      {
        "nr": 91891
      },
      {
        "nr": 4040
      },
      {
        "nr": 5714
      },
      {
        "nr": 91890
      },
      {
        "nr": 47558
      },
      {
        "nr": 62107
      },
      {
        "nr": 48681
      },
      {
        "nr": 45369
      },
      {
        "nr": 11174
      },
      {
        "nr": 5692
      },
      {
        "nr": 6799
      },
      {
        "nr": 48676
      },
      {
        "nr": 48683
      },
      {
        "nr": 69108
      },
      {
        "nr": 45394
      },
      {
        "nr": 47555
      },
      {
        "nr": 36257
      },
      {
        "nr": 48682
      },
      {
        "nr": 4019
      },
      {
        "nr": 5691
      },
      {
        "nr": 48677
      },
      {
        "nr": 36258
      },
      {
        "nr": 62087
      },
      {
        "nr": 48678
      },
      {
        "nr": 5621
      },
      {
        "nr": 5575
      },
      {
        "nr": 5576
      },
      {
        "nr": 14012
      },
      {
        "nr": 50623
      },
      {
        "nr": 47736
      },
      {
        "nr": 3866057
      },
      {
        "nr": 14013
      },
      {
        "nr": 47760
      },
      {
        "nr": 14053
      },
      {
        "nr": 45411
      },
      {
        "nr": 59530
      },
      {
        "nr": 59523
      },
      {
        "nr": 45428
      },
      {
        "nr": 45412
      },
      {
        "nr": 45407
      },
      {
        "nr": 4110
      },
      {
        "nr": 45410
      },
      {
        "nr": 25399
      },
      {
        "nr": 5739
      },
      {
        "nr": 62219
      },
      {
        "nr": 23563
      },
      {
        "nr": 4085
      },
      {
        "nr": 4084
      },
      {
        "nr": 56288
      },
      {
        "nr": 4079
      },
      {
        "nr": 91914
      },
      {
        "nr": 62231
      },
      {
        "nr": 4088
      },
      {
        "nr": 25414
      },
      {
        "nr": 5728
      },
      {
        "nr": 45427
      },
      {
        "nr": 47734
      },
      {
        "nr": 91838
      },
      {
        "nr": 5724
      },
      {
        "nr": 59511
      },
      {
        "nr": 45406
      },
      {
        "nr": 62218
      },
      {
        "nr": 47777
      },
      {
        "nr": 14071
      },
      {
        "nr": 62220
      },
      {
        "nr": 31506
      },
      {
        "nr": 45430
      },
      {
        "nr": 5737
      },
      {
        "nr": 59507
      },
      {
        "nr": 62193
      },
      {
        "nr": 55267
      },
      {
        "nr": 14061
      },
      {
        "nr": 62237
      },
      {
        "nr": 14052
      },
      {
        "nr": 59506
      },
      {
        "nr": 62233
      },
      {
        "nr": 62235
      },
      {
        "nr": 23564
      },
      {
        "nr": 14067
      },
      {
        "nr": 5734
      },
      {
        "nr": 47791
      },
      {
        "nr": 23494
      },
      {
        "nr": 4030
      },
      {
        "nr": 4064
      },
      {
        "nr": 5733
      },
      {
        "nr": 14068
      },
      {
        "nr": 45426
      },
      {
        "nr": 45435
      },
      {
        "nr": 62161
      },
      {
        "nr": 14054
      },
      {
        "nr": 4078
      },
      {
        "nr": 23141
      },
      {
        "nr": 5729
      },
      {
        "nr": 14070
      },
      {
        "nr": 62195
      },
      {
        "nr": 13632
      },
      {
        "nr": 13981
      },
      {
        "nr": 5772
      },
      {
        "nr": 47559
      },
      {
        "nr": 62257
      },
      {
        "nr": 5743
      },
      {
        "nr": 61593
      },
      {
        "nr": 45471
      },
      {
        "nr": 4136
      },
      {
        "nr": 1118
      },
      {
        "nr": 5774
      },
      {
        "nr": 62303
      },
      {
        "nr": 62243
      },
      {
        "nr": 47794
      },
      {
        "nr": 4124
      },
      {
        "nr": 3010
      },
      {
        "nr": 62291
      },
      {
        "nr": 23596
      },
      {
        "nr": 62271
      },
      {
        "nr": 45505
      },
      {
        "nr": 3440
      },
      {
        "nr": 45487
      },
      {
        "nr": 5766
      },
      {
        "nr": 62287
      },
      {
        "nr": 59524
      },
      {
        "nr": 22093
      },
      {
        "nr": 45422
      },
      {
        "nr": 23537
      },
      {
        "nr": 23558
      },
      {
        "nr": 22092
      },
      {
        "nr": 45404
      },
      {
        "nr": 14076
      },
      {
        "nr": 5269
      },
      {
        "nr": 3006
      },
      {
        "nr": 14090
      },
      {
        "nr": 14102
      },
      {
        "nr": 62258
      },
      {
        "nr": 45489
      },
      {
        "nr": 45496
      },
      {
        "nr": 45447
      },
      {
        "nr": 45506
      },
      {
        "nr": 11089
      },
      {
        "nr": 14075
      },
      {
        "nr": 62276
      },
      {
        "nr": 62267
      },
      {
        "nr": 13835
      },
      {
        "nr": 4123
      },
      {
        "nr": 62227
      },
      {
        "nr": 1797128
      },
      {
        "nr": 1136
      },
      {
        "nr": 14058
      },
      {
        "nr": 91931
      },
      {
        "nr": 45475
      },
      {
        "nr": 45405
      },
      {
        "nr": 4098
      },
      {
        "nr": 62260
      },
      {
        "nr": 45508
      },
      {
        "nr": 56332
      },
      {
        "nr": 45462
      },
      {
        "nr": 3443
      },
      {
        "nr": 45485
      },
      {
        "nr": 62159
      },
      {
        "nr": 62255
      },
      {
        "nr": 4097
      },
      {
        "nr": 4133
      },
      {
        "nr": 23538
      },
      {
        "nr": 45498
      },
      {
        "nr": 1122
      },
      {
        "nr": 5771
      },
      {
        "nr": 3007
      },
      {
        "nr": 47370
      },
      {
        "nr": 45461
      },
      {
        "nr": 62265
      },
      {
        "nr": 23539
      },
      {
        "nr": 14100
      },
      {
        "nr": 45504
      },
      {
        "nr": 59548
      },
      {
        "nr": 91930
      },
      {
        "nr": 23597
      },
      {
        "nr": 45477
      },
      {
        "nr": 5794
      },
      {
        "nr": 45570
      },
      {
        "nr": 62331
      },
      {
        "nr": 22095
      },
      {
        "nr": 59559
      },
      {
        "nr": 14133
      },
      {
        "nr": 13900
      },
      {
        "nr": 14134
      },
      {
        "nr": 45573
      },
      {
        "nr": 91924
      },
      {
        "nr": 47774
      },
      {
        "nr": 1160
      },
      {
        "nr": 3039
      },
      {
        "nr": 62285
      },
      {
        "nr": 59576
      },
      {
        "nr": 5800
      },
      {
        "nr": 45572
      },
      {
        "nr": 45574
      },
      {
        "nr": 4155
      },
      {
        "nr": 3029
      },
      {
        "nr": 62315
      },
      {
        "nr": 4135
      },
      {
        "nr": 45555
      },
      {
        "nr": 62247
      },
      {
        "nr": 13929
      },
      {
        "nr": 1159
      },
      {
        "nr": 45569
      },
      {
        "nr": 62312
      },
      {
        "nr": 62324
      },
      {
        "nr": 4134
      },
      {
        "nr": 47775
      },
      {
        "nr": 3047
      },
      {
        "nr": 1161
      },
      {
        "nr": 59566
      },
      {
        "nr": 56435
      },
      {
        "nr": 14117
      },
      {
        "nr": 11027
      },
      {
        "nr": 62346
      },
      {
        "nr": 3054
      },
      {
        "nr": 5808
      },
      {
        "nr": 91950
      },
      {
        "nr": 1186
      },
      {
        "nr": 1165
      },
      {
        "nr": 2757
      },
      {
        "nr": 45530
      },
      {
        "nr": 62343
      },
      {
        "nr": 62357
      },
      {
        "nr": 10825
      },
      {
        "nr": 3038
      },
      {
        "nr": 5799
      },
      {
        "nr": 13899
      },
      {
        "nr": 14003
      },
      {
        "nr": 45571
      },
      {
        "nr": 14115
      },
      {
        "nr": 45551
      },
      {
        "nr": 45517
      },
      {
        "nr": 3030
      },
      {
        "nr": 45567
      },
      {
        "nr": 45568
      },
      {
        "nr": 1797243
      },
      {
        "nr": 3875467
      },
      {
        "nr": 91946
      },
      {
        "nr": 59525
      },
      {
        "nr": 14118
      },
      {
        "nr": 91923
      },
      {
        "nr": 3048
      },
      {
        "nr": 91932
      },
      {
        "nr": 62311
      },
      {
        "nr": 3040
      },
      {
        "nr": 45552
      },
      {
        "nr": 5659
      },
      {
        "nr": 62304
      },
      {
        "nr": 3028
      },
      {
        "nr": 45532
      },
      {
        "nr": 3052
      },
      {
        "nr": 23584
      },
      {
        "nr": 3042
      },
      {
        "nr": 1144
      },
      {
        "nr": 22110
      },
      {
        "nr": 62238
      },
      {
        "nr": 4152
      },
      {
        "nr": 45556
      },
      {
        "nr": 91792
      },
      {
        "nr": 4154
      },
      {
        "nr": 14123
      },
      {
        "nr": 62320
      },
      {
        "nr": 59569
      },
      {
        "nr": 14087
      },
      {
        "nr": 45553
      },
      {
        "nr": 62375
      },
      {
        "nr": 5421
      },
      {
        "nr": 1203
      },
      {
        "nr": 45431
      },
      {
        "nr": 3070
      },
      {
        "nr": 62306
      },
      {
        "nr": 13514
      },
      {
        "nr": 1236
      },
      {
        "nr": 14186
      },
      {
        "nr": 45470
      },
      {
        "nr": 5782
      },
      {
        "nr": 1149
      },
      {
        "nr": 5756
      },
      {
        "nr": 3001
      },
      {
        "nr": 4185
      },
      {
        "nr": 48748
      },
      {
        "nr": 62373
      },
      {
        "nr": 4138
      },
      {
        "nr": 1151
      },
      {
        "nr": 3096
      },
      {
        "nr": 3087
      },
      {
        "nr": 4166
      },
      {
        "nr": 5797
      },
      {
        "nr": 62390
      },
      {
        "nr": 3083
      },
      {
        "nr": 5845
      },
      {
        "nr": 4184
      },
      {
        "nr": 91964
      },
      {
        "nr": 62088
      },
      {
        "nr": 3877237
      },
      {
        "nr": 5596454
      },
      {
        "nr": 91962
      },
      {
        "nr": 4165
      },
      {
        "nr": 14159
      },
      {
        "nr": 62377
      },
      {
        "nr": 5781
      },
      {
        "nr": 4191
      },
      {
        "nr": 62412
      },
      {
        "nr": 3069
      },
      {
        "nr": 14151
      },
      {
        "nr": 48747
      },
      {
        "nr": 62411
      },
      {
        "nr": 5818
      },
      {
        "nr": 47626
      },
      {
        "nr": 3086
      },
      {
        "nr": 1205
      },
      {
        "nr": 62328
      },
      {
        "nr": 62374
      },
      {
        "nr": 5817
      },
      {
        "nr": 4180
      },
      {
        "nr": 4137
      },
      {
        "nr": 5842
      },
      {
        "nr": 1222
      },
      {
        "nr": 45557
      },
      {
        "nr": 1215
      },
      {
        "nr": 5779
      },
      {
        "nr": 62401
      },
      {
        "nr": 4187
      },
      {
        "nr": 91706
      },
      {
        "nr": 62290
      },
      {
        "nr": 3095
      },
      {
        "nr": 45620
      },
      {
        "nr": 62395
      },
      {
        "nr": 25136
      },
      {
        "nr": 14152
      },
      {
        "nr": 1206
      },
      {
        "nr": 59587
      },
      {
        "nr": 11717
      },
      {
        "nr": 1219
      },
      {
        "nr": 4188
      },
      {
        "nr": 4181
      },
      {
        "nr": 5783
      },
      {
        "nr": 5786
      },
      {
        "nr": 4216
      },
      {
        "nr": 45709
      },
      {
        "nr": 3132
      },
      {
        "nr": 45691
      },
      {
        "nr": 62465
      },
      {
        "nr": 62468
      },
      {
        "nr": 62381
      },
      {
        "nr": 3133
      },
      {
        "nr": 1260
      },
      {
        "nr": 5872
      },
      {
        "nr": 45693
      },
      {
        "nr": 1271
      },
      {
        "nr": 59586
      },
      {
        "nr": 1244
      },
      {
        "nr": 28755
      },
      {
        "nr": 3018
      },
      {
        "nr": 1797249
      },
      {
        "nr": 11091
      },
      {
        "nr": 14120
      },
      {
        "nr": 62417
      },
      {
        "nr": 45690
      },
      {
        "nr": 4042
      },
      {
        "nr": 47170
      },
      {
        "nr": 14119
      },
      {
        "nr": 1284
      },
      {
        "nr": 3148
      },
      {
        "nr": 45674
      },
      {
        "nr": 5785
      },
      {
        "nr": 59596
      },
      {
        "nr": 1797245
      },
      {
        "nr": 62418
      },
      {
        "nr": 5858
      },
      {
        "nr": 91961
      },
      {
        "nr": 5851
      },
      {
        "nr": 62408
      },
      {
        "nr": 14218
      },
      {
        "nr": 5787
      },
      {
        "nr": 45667
      },
      {
        "nr": 14104
      },
      {
        "nr": 14217
      },
      {
        "nr": 62448
      },
      {
        "nr": 1272
      },
      {
        "nr": 62464
      },
      {
        "nr": 5761
      },
      {
        "nr": 3143
      },
      {
        "nr": 62454
      },
      {
        "nr": 47723
      },
      {
        "nr": 3017
      },
      {
        "nr": 62382
      },
      {
        "nr": 4043
      },
      {
        "nr": 57726
      },
      {
        "nr": 45676
      },
      {
        "nr": 62469
      },
      {
        "nr": 62447
      },
      {
        "nr": 5873
      },
      {
        "nr": 3134
      },
      {
        "nr": 62280
      },
      {
        "nr": 14182
      },
      {
        "nr": 5623
      },
      {
        "nr": 47658
      },
      {
        "nr": 3024
      },
      {
        "nr": 14164
      },
      {
        "nr": 56711
      },
      {
        "nr": 3022
      },
      {
        "nr": 62472
      },
      {
        "nr": 14165
      },
      {
        "nr": 23590
      },
      {
        "nr": 14163
      },
      {
        "nr": 15134
      },
      {
        "nr": 3023
      },
      {
        "nr": 91986
      },
      {
        "nr": 14166
      },
      {
        "nr": 91987
      },
      {
        "nr": 23493
      },
      {
        "nr": 14188
      },
      {
        "nr": 5596112
      },
      {
        "nr": 14190
      },
      {
        "nr": 45700
      },
      {
        "nr": 24057
      },
      {
        "nr": 5820
      },
      {
        "nr": 45682
      },
      {
        "nr": 24058
      },
      {
        "nr": 14187
      },
      {
        "nr": 14189
      },
      {
        "nr": 23535
      },
      {
        "nr": 5868
      },
      {
        "nr": 45701
      },
      {
        "nr": 3173
      },
      {
        "nr": 62513
      },
      {
        "nr": 59638
      },
      {
        "nr": 1349
      },
      {
        "nr": 45796
      },
      {
        "nr": 23410
      },
      {
        "nr": 4226
      },
      {
        "nr": 19001
      },
      {
        "nr": 62490
      },
      {
        "nr": 26128
      },
      {
        "nr": 14257
      },
      {
        "nr": 62481
      },
      {
        "nr": 45792
      },
      {
        "nr": 59632
      },
      {
        "nr": 45794
      },
      {
        "nr": 62486
      },
      {
        "nr": 4238
      },
      {
        "nr": 45795
      },
      {
        "nr": 23408
      },
      {
        "nr": 62372
      },
      {
        "nr": 47597
      },
      {
        "nr": 59640
      },
      {
        "nr": 14246
      },
      {
        "nr": 62516
      },
      {
        "nr": 1294
      },
      {
        "nr": 62488
      },
      {
        "nr": 4245
      },
      {
        "nr": 23411
      },
      {
        "nr": 5890
      },
      {
        "nr": 4252
      },
      {
        "nr": 62503
      },
      {
        "nr": 111680
      },
      {
        "nr": 1340
      },
      {
        "nr": 1293
      },
      {
        "nr": 23425
      },
      {
        "nr": 5748
      },
      {
        "nr": 62478
      },
      {
        "nr": 4198
      },
      {
        "nr": 243306
      },
      {
        "nr": 10993
      },
      {
        "nr": 45751
      },
      {
        "nr": 14259
      },
      {
        "nr": 3172
      },
      {
        "nr": 5900
      },
      {
        "nr": 59631
      },
      {
        "nr": 14239
      },
      {
        "nr": 62507
      },
      {
        "nr": 14123
      },
      {
        "nr": 62492
      },
      {
        "nr": 47701
      },
      {
        "nr": 3175
      },
      {
        "nr": 14245
      },
      {
        "nr": 1962
      },
      {
        "nr": 62497
      },
      {
        "nr": 45800
      },
      {
        "nr": 1325
      },
      {
        "nr": 5899
      },
      {
        "nr": 5746
      },
      {
        "nr": 45757
      },
      {
        "nr": 23406
      },
      {
        "nr": 45745
      },
      {
        "nr": "037666"
      },
      {
        "nr": 48815
      },
      {
        "nr": 45793
      },
      {
        "nr": 4244
      },
      {
        "nr": 4289
      },
      {
        "nr": 19027
      },
      {
        "nr": 62432
      },
      {
        "nr": 61644
      },
      {
        "nr": 1357
      },
      {
        "nr": 59659
      },
      {
        "nr": 14022
      },
      {
        "nr": 3213
      },
      {
        "nr": 5879
      },
      {
        "nr": 14269
      },
      {
        "nr": 62579
      },
      {
        "nr": 14230
      },
      {
        "nr": 19058
      },
      {
        "nr": 1797130
      },
      {
        "nr": 19041
      },
      {
        "nr": 14285
      },
      {
        "nr": 5926
      },
      {
        "nr": 4145
      },
      {
        "nr": 62580
      },
      {
        "nr": 3192
      },
      {
        "nr": 19060
      },
      {
        "nr": 1392
      },
      {
        "nr": 16148
      },
      {
        "nr": 25792
      },
      {
        "nr": 1798778
      },
      {
        "nr": 5692
      },
      {
        "nr": 23382
      },
      {
        "nr": 59654
      },
      {
        "nr": 3178
      },
      {
        "nr": 11384
      },
      {
        "nr": 19051
      },
      {
        "nr": 4277
      },
      {
        "nr": 14259
      },
      {
        "nr": 1797165
      },
      {
        "nr": 2033
      },
      {
        "nr": 62560
      },
      {
        "nr": 5878
      },
      {
        "nr": 3046
      },
      {
        "nr": 1150
      },
      {
        "nr": 19063
      },
      {
        "nr": 19065
      },
      {
        "nr": 59675
      },
      {
        "nr": 16136
      },
      {
        "nr": 13998
      },
      {
        "nr": 4273
      },
      {
        "nr": 1364
      },
      {
        "nr": 62480
      },
      {
        "nr": 19064
      },
      {
        "nr": 62578
      },
      {
        "nr": 5914
      },
      {
        "nr": 62585
      },
      {
        "nr": 23311
      },
      {
        "nr": 62573
      },
      {
        "nr": 48837
      },
      {
        "nr": 19061
      },
      {
        "nr": 5927
      },
      {
        "nr": 14283
      },
      {
        "nr": 59663
      },
      {
        "nr": 62571
      },
      {
        "nr": 55472
      },
      {
        "nr": 62587
      },
      {
        "nr": 62470
      },
      {
        "nr": 59661
      },
      {
        "nr": 62572
      },
      {
        "nr": 62532
      },
      {
        "nr": 14284
      },
      {
        "nr": 4104
      },
      {
        "nr": 5880
      },
      {
        "nr": 4197
      },
      {
        "nr": 14280
      },
      {
        "nr": 23312
      },
      {
        "nr": 62582
      },
      {
        "nr": 14021
      },
      {
        "nr": 4274
      },
      {
        "nr": 62575
      },
      {
        "nr": 5747
      },
      {
        "nr": 19062
      },
      {
        "nr": 62556
      },
      {
        "nr": 5838
      },
      {
        "nr": 62521
      },
      {
        "nr": 16138
      },
      {
        "nr": 59660
      },
      {
        "nr": 62546
      },
      {
        "nr": 59672
      },
      {
        "nr": 16147
      },
      {
        "nr": 5925
      },
      {
        "nr": 23295
      },
      {
        "nr": 11386
      },
      {
        "nr": 5934
      },
      {
        "nr": 5932
      },
      {
        "nr": 8420
      },
      {
        "nr": 5928
      },
      {
        "nr": 4256
      },
      {
        "nr": 2099
      },
      {
        "nr": 14232
      },
      {
        "nr": 62603
      },
      {
        "nr": 23582
      },
      {
        "nr": 45711
      },
      {
        "nr": 62614
      },
      {
        "nr": 19109
      },
      {
        "nr": 3237
      },
      {
        "nr": 5596121
      },
      {
        "nr": 3084
      },
      {
        "nr": 59676
      },
      {
        "nr": 3116
      },
      {
        "nr": 62590
      },
      {
        "nr": 19050
      },
      {
        "nr": 5912
      },
      {
        "nr": 62621
      },
      {
        "nr": 62604
      },
      {
        "nr": 62577
      },
      {
        "nr": 19107
      },
      {
        "nr": 91998
      },
      {
        "nr": 3233
      },
      {
        "nr": 243343
      },
      {
        "nr": 14233
      },
      {
        "nr": 19123
      },
      {
        "nr": 4301
      },
      {
        "nr": 5596113
      },
      {
        "nr": 3235
      },
      {
        "nr": 20190
      },
      {
        "nr": 92004
      },
      {
        "nr": 5952
      },
      {
        "nr": 8428
      },
      {
        "nr": 10499
      },
      {
        "nr": 56540
      },
      {
        "nr": 45354
      },
      {
        "nr": 5941
      },
      {
        "nr": 14290
      },
      {
        "nr": 91988
      },
      {
        "nr": 1408
      },
      {
        "nr": 4336
      },
      {
        "nr": 48862
      },
      {
        "nr": 19092
      },
      {
        "nr": 4306
      },
      {
        "nr": 23183
      },
      {
        "nr": 22260
      },
      {
        "nr": 19118
      },
      {
        "nr": 62524
      },
      {
        "nr": 19122
      },
      {
        "nr": 45712
      },
      {
        "nr": 3236
      },
      {
        "nr": 5933
      },
      {
        "nr": 8407
      },
      {
        "nr": 48861
      },
      {
        "nr": 92012
      },
      {
        "nr": 62190
      },
      {
        "nr": 21109
      },
      {
        "nr": 21332
      },
      {
        "nr": 45710
      },
      {
        "nr": 23583
      },
      {
        "nr": 4310
      },
      {
        "nr": 19093
      },
      {
        "nr": 4255
      },
      {
        "nr": 62612
      },
      {
        "nr": 62597
      },
      {
        "nr": 19094
      },
      {
        "nr": 19096
      },
      {
        "nr": 14083
      },
      {
        "nr": 19105
      },
      {
        "nr": 8427
      },
      {
        "nr": 4314
      },
      {
        "nr": 62593
      },
      {
        "nr": 1305
      },
      {
        "nr": 62526
      },
      {
        "nr": 45713
      }
    ]
    
    
        var array1 = pharmec.map(data => {
            return  data.slice(7, 13);
            
            //return data;
        })
        var array2 = availables.map(data => {
            return JSON.stringify(data.nr, null, 4);
        })

        var tempArr = array2.filter(function(item) {
        return !array1.includes(item); 
        });
        array1 = array1.filter(function(item) {
        return !array2.includes(item); 
        });
        array2 = tempArr;


console.log("Array from Order by date", array1); 
console.log("Array from Available by date without reserved hours ", availables.nr);
 //{ <pre>{JSON.stringify(pharmec, null, 4)}</pre> }
   
    return (
        <>    
            <div className="container-fluid">
            <h1 className="text-title-blue text-center py-4">Retete </h1>         
            
            <div>
                {array1 && array1.map((h) => (
                <h3>  {h} </h3>
                ))}
            </div>
            </div> 
        </>        
    );
};

export default HeliumDash;