import axios from "axios";
import { API } from "../config";


export const createPortofolio = async (portofolio, userId, token) =>{
    console.log(portofolio);
  await axios.post(`${API}/portofolio/create/${userId}`, portofolio, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  })};

export const removePortofolio = async (slug, userId, token) =>
  await axios.delete(`${API}/portofolio/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getPortofolio = async (slug) =>
  await axios.get(`${API}/portofolio/${slug}`);

export const getPortofolios = async () =>
  await axios.get(`${API}/portofolios`);