import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const NeedHelp = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid py-4 mt-4 TextStyle bg-light" >  
            <h2 className="text-dark d-flex justify-content-center mt-4">Aveti nevoie de ajutor?</h2>          
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-muted">
                        <img className="card-img-top py-4" src="/img/softeh/baza.png" alt="Card" style={{ width: "5rem" }}></img><br/>                        
                            <h4 className="text-muted">Accesați baza noastră de cunoștințe</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }} className="h6"> Suntem buni deoarece avem experienta. Șansele sunt că am văzut întrebarea dvs. înainte.
                        </p>
                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-muted h6">
                        <img className="card-img-top py-4" src="/img/impreuna.png" alt="Card" style={{ width: "5rem" }}></img><br/>
                        <h4 className="text-muted">Intrati in comunitatea noastră</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }}>Servim o mare varietate de clienți, într-o gamă largă de industrii.  </p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-muted h6">
                        <img className="card-img-top py-4" src="/img/contact.png" alt="Card" style={{ width: "5rem" }}></img><br/>
                        <h4 className="text-muted">Apeleaza-ne sau trimite-ne o cerere </h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} >Suntem aici pentru dumneavostra dacă aveți nevoie de ajutor.   </p>                  
                    </div>                    
                </div>                                
            </div>               
        </div>      
    );
};

export default NeedHelp;