import React from "react";
import { Card } from 'antd';
import { FolderViewOutlined  } from "@ant-design/icons";

const { Meta } = Card

const Portofolio = ({portofolios}) => {    

        
    return (
        <div>
            <div className="container-fluid py-4 TextStyle bg-light" >   
            <h2 className="text-dark text-center mt-4">Portofoliu</h2>  
                <div className="row d-flex justify-content-center mt-4 mb-4">                 
                    {portofolios && portofolios.map((p) => (
                        <div className="col-md-4 mt-2" key={p._id}>
                            <Card
                            className="text-primary h5" 
                            hoverable                            
                            title={p.title}                            
                            cover={
                                <img
                                src={`${process.env.REACT_APP_API}/portofolio/photo/${p._id}`}
                                style={{ height: "100", width: "200", objectFit: "cover" }}                                
                                />
                            }     
                            actions={[
                                <a href={p.plink} target="_blank">
                                <FolderViewOutlined  className="text-info" /> <br /> Vizualizeaza Proiect
                                </a>,                                       
                            ]}                       
                        >    
                        <Meta
                                title={`Project Category: ${p.category}`}
                                description={`Project Technology: ${p.tehnology}`} 
                                className="text-left"                 
                        />                         
                        </Card>  
                        </div>
                    ))}                                            
                                                                      
                    </div>                                                    
                </div>             
        </div>             
    );
};

export default Portofolio;