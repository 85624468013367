import React from "react";
import { Select } from "antd";

const { Option } = Select;

const WorkstationCreateForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
   
}) => {
  // destructure
  const {
    title,
    companys,
    company,
    adress,
    phone,    
    email,  
    description,
  } = values;
  console.log(values);

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={title}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
                <label>Company </label>
                <Select          
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  name="company"
                  value={company}
                  onChange={(value) => setValues({ ...values, company: value })}
                >          
                  {companys.length > 0 &&
                    companys.map((c) => (
                        <Option key={c.title} value={c._id}>
                            {c.title}
                        </Option>                        
                    ))}
                </Select>
              </div>

            <div className="form-group">
      <label>Adresa</label>
      <input
        type="text"
        name="adress"
        className="form-control"
        value={adress}
        onChange={handleChange}
      />
    </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Telefon</label>
              <input
                type="text"
                name="phone"
                className="form-control"
                value={phone}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                name="email"
                className="form-control"
                value={email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
      <label>Description</label>
      <input
        type="text"
        name="description"
        className="form-control"
        value={description}
        onChange={handleChange}
      />
    </div>   
          </div>         
        </div>
        <br />
        <button className="btn btn-outline-info">Save</button>
      </div>
  </form>
  );
};

export default WorkstationCreateForm;
