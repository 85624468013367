import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Module = () => {   

  
    return (
        <div>
            <div className="container-fluid py-4 TextStyle bg-light" >   
            <h2 className="text-title-blue text-center">Module</h2>         
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-light ">
                        <img className="card-img-top py-4" src="/img/softeh/promotii.png" alt="Card" style={{ width: "4rem" }}></img><br/>                        
                            <h4 className="text-dark">Promotii</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }} className="text-muted"> Modulul Promotii permite definirea, organizarea si derularea diferitelor campanii, planificate 
                       pe intervale de timp. Modulul permite prioritizarea aplicarii promotiilor, aplicarea discount-urilor procentuale sau valorice, acordarea de produse 
                       cadou, etc.</p>                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/fidelitate.png" alt="Card" style={{ width: "4rem" }}></img><br/>
                        <h4 className="text-dark">Carduri de fidelitate</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Modulul carduri de fidelitate permite utilizarea de carduri pentru fidelizarea clientilor 
                        si acordarea de reduceri in mai multe moduri: prin acumulare de puncte, procentual sau valorice. Profilul clientului - reprezinta o privire de ansamblu 
                        asupra istoricului de achizitii ale acestuia.</p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/asocieri.png" alt="Card" style={{ width: "4rem" }}></img><br/>
                        <h4 className="text-dark">Asocieri recomandate</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Modulul Asocieri permite propunerea la vanzare a unor produse asociate in functie de o serie 
                        de prioritizari definite de utilizator. Printre criteriile de prioritizare se pot utiliza diversi indicatori, cum ar fi: media ponderata a adaosului comercial,
                         viteza de rotatie a stocului sau discountul procentual atribuit produsului in factura furnizor. </p>                  
                    </div>                    
                </div>     
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-light ">
                        <img className="card-img-top py-4" src="/img/softeh/identificare.png" alt="Card" style={{ width: "4rem" }}></img><br/>                        
                            <h4 className="text-dark">Identificare Specifica</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }} className="text-muted"> Modulul de identificare specifica permite utilizarea urmatoarelor tipuri de coduri de bare: 
                       Producator (sunt cele existente pe cutiile medicamentelor), Netipizat (coduri generate intern de aplicatie pe linia de intrare), Tipizat (coduri unice 
                       la nivel de cutie – rola coduri de bare). </p>                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/preluare.png" alt="Card" style={{ width: "4rem" }}></img><br/>
                        <h4 className="text-dark">Factura Electronica</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Modulul de preluare facturi de la furnizori este intr-o continua dezvoltare astfel incat 
                        facturile sa fie importate automat de la cat mai multi furnizori, micsorand timpul de operare al documentelor in farmacie.</p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/comenzi.png" alt="Card" style={{ width: "4rem" }}></img><br/>
                        <h4 className="text-dark">Comenzi</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Modulul de comenzi ajuta in organizarea si controlul procesului de achizitie marfa de la furnizori.
                         De asemenea, modulul ofera utilizatorului informatiile cele mai importante pentru activitatea de aprovizionare: istoric comenzi, istoric achizitii, 
                         istoric discount, etc. </p>                  
                    </div>                    
                </div>                              
            </div>               
        </div>             
    );
};

export default Module;