import React, { useState } from "react";
import { toast } from "react-toastify";
import ContactForm from "../forms/ContactForm";
import { createContact } from "../../functions/contact";
import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
    name: "",     
    email: "",
    services: "", 
    contactId: `${new Date().getTime()}`    
  };


const BottomPage = () => {    
    const [values, setValues] = useState(initialState); 
    const [loading, setLoading] = useState(false);    

    const loadDefault = () => setValues({name: "", email: "", services: "", contactId: `${new Date().getTime()}`});

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        createContact(values)
          .then((res) => {
            setLoading(false);
            toast.success(`Cererea dumneavoastra a fost trimisa cu succes`);
            loadDefault();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 400) toast.error(err.response.data);
            //toast.error(err.response.data.err);
          });
      };
    
      const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        // console.log(e.target.name, " ----- ", e.target.value);
      };    
   
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4">            
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-4 mt-4 text-center" >                       
                    <h4 className="text-dark">Va multumim pentru atentie!</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Pentru orice fel de intrebari va rugam sa ne contactati folosind detaliile de mai jos: </p> 
                        {loading ? (
                            <LoadingOutlined className="text-danger h1" />
                            ) : (
                                <ContactForm 
                                handleSubmit={handleSubmit}
                                handleChange={handleChange}
                                setValues={setValues}
                                values={values} />
                            )}
                        
                    </div>                 
                    <div className="col-md-4 text-center" >            
                    <img className="card-img-top py-4" src="/img/softeh/softehPlus.png" alt="SoftehPlus" style={{ width: "25rem" }}></img> 
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default BottomPage;