import React, { useState } from "react";
import { Link } from "react-router-dom";
//import ShowImage from "./ShowImage";
//import moment from "moment";
//import { addItem, updateItem, removeItem } from "./cartHelpers";
import { updatePassReset } from "../pages/admin/apiAdmin";
import styled from 'styled-components';
//import {ButtonContainer} from './Button';
import { isAuthenticated } from "../pages/auth";


const SNVM = ({
    product,
    showResetButton = false,    
    showBackButton = false,
    linkCard = true
}) => {
    //const [redirect, setRedirect] = useState(false);
   // const [count, setCount] = useState(product.resetpass);
    const { user, token } = isAuthenticated();
    const [success, setSuccess] = useState(false); 

    const handleReset = (productId) => {        
        updatePassReset(user._id, token, productId).then(
            data => {
                if (data.error) {
                    console.log("Reset failed");
                } else {
                    setSuccess(true);
                    {showTime()};                   
                }
            }
        );
    }; 
 
     const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">{product.plucru} a fost resetata!</h3>;
        }
    };

    const showReset = showResetButton => {
        return (
            showResetButton && (
                <button
                    onClick={() => handleReset(product._id)}
                    className="addButton mt-2 mb-2"
                >
                 <i className="far fa-clock"></i>   Reset pass timer
                </button>
            )
        );
    };

   
    const showBack = showBackButton => {
        return (
            showBackButton && (
                <div className="mt-5">
            <Link to="/admin/snvm" className="text-warning">
                Back to Clients
            </Link>
        </div>
            )
        );
    };

    const showTime = resetpass => {
        let countDownDate = new Date(product.updatedAt).getTime();
        let now = new Date().getTime();
        let distance = now - countDownDate;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let resset = product.resetpass - days;
        return resset > 5 ? (
            <span className="badge badge-primary badge-pill">Reset LUF in {resset} zile</span>
        ) : (
            <span className="badge badge-danger badge-pill">Schimati parola! Mai aveti {resset} zile</span>
        );
    };

    const showsLinkCard = linkCard => {
        return (
            linkCard && (
                <div className="container-fluid">    
                      
                <div className="card container-fluid text-center">
                <Link to={`/product/${product._id}`}>                 
                <h5 className="lead mt-2 ">
                    <span className="text-blue font-italic mb-0">Punct de lucru: {product.plucru} </span>                    
                </h5>                             
                <div className="card-footer d-flex text-center justify-content-center">
                            <h5 className="text-blue font-italic ">
                            <span className="mr-1">{showTime(product.resetpass)}</span><br />
                            <span className="mr-1">{showReset(showResetButton)}</span>
                        </h5>                       
                </div>  
                <br />              
                </Link>
                {showSuccess()}
                 {showBack(showBackButton)}             
                </div>
             
        </div>
            )
        );
    };

    return (
        <ProductWrapper className=" mx-auto ">
            {showsLinkCard(linkCard)}
            
        </ProductWrapper>
    );
};

const ProductWrapper = styled.div`
    .card{
        border-color:transparent;
        transition:all 1s linear;
    }
    .card-footer{
        background: transparent;
        border-top: transparent;
        transition:all 1s linear;
    }
    &:hover{
        .card{
            border:0.04rem solid rgba(0,0,0,0.2);
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
        }
        .card-footer{
            background:rgba(247,247,247);
        }
        
    }
    .img-container{
        position:relative;
        overflow:hidden;
    }
    .card-img-top{
        transition: all 1s linear;
    }
    .img-container:hover .card-img-top{
        transform:scale(1.2);
    }
    .cart-btn{
        position:absolute;
        bottom: 0;
        right: 0;
        padding: 0.2rem 0.4rem;
        background: var(--lightBlue);
        border: none;
        color: var(--mainWhite);
        font-size: 1.4rem;
        border-radius: 0.5rem 0 0 0;
        transform: translate(100%, 100%);
        transition:all 1s linear;
    }
    .img-container:hover .cart-btn{ 
        transform: translate(0, 0); 
        
    }
    .cart-btn:hover {
        color: var(--mainBlue);
        cursor: pointer;
    }

    .addButton {
  text-transform:capitalize;
  font-size:1.2rem;
  background: transparent;
  border:0.05rem solid var(--lightBlue);
  border-color: var(--lightBlue);
  color: var(--lightBlue);
  border-radius:0.5rem;
  pading: 0.2rem 0.5rem;
  cursor:pointer;
  margin: 0.2rem 0.5rem 0.2rem 0;
  transition: all 0.5s ease-in-out;
  &:hover{
    background: var(--lightBlue);
    color:var(--mainWhite);
  }
  &:focus{
    outline:none;
  }
    }
`;

export default SNVM;
