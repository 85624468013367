import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Avantaje = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid py-4 TextStyle" >   
            <h2 className="text-title-blue text-center">Avantaje</h2>         
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-light ">
                        <img className="card-img-top py-4" src="/img/softeh/accesibil.png" alt="Card" style={{ width: "3rem" }}></img><br/>                        
                            <h4 className="text-dark">Accesibil</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }} className="text-muted"> Variantele de abonament lunar in sistem SaaS(Software as a Service) permit planuri eficiente din punct de vedere 
                       al preturilor, cu rezultate pozitive vizibile in zona de costuri ale beneficiarului. Partenerii nostri beneficiaza de cele mai moderne tehnologii cloud
                        si nu numai (Microsft, Amazon WS, Google).</p>                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/cloud.png" alt="Card" style={{ width: "3rem" }}></img><br/>
                        <h4 className="text-dark">Bazat pe Cloud</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Pentru farmaciile independente, lucrul in cloud permite o accesibilitate mai mare a sistemului la 
                        costuri reduse. Pentru lanturi de farmacii, modul de lucru online, utilizand o singura baza de date centrala, permite eliminarea replicarilor de date si
                         o privire de ansamblu imediata de la centru asupra situatiei vanzarilor. </p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/integrare.png" alt="Card" style={{ width: "3rem" }}></img><br/>
                        <h4 className="text-dark">Integrare avansata</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Sistemul poate fi integrat cu o gama larga de case de marcat, imprimante fiscale, sisteme de
                         tip POS etc. De asemenea pot fi foarte usor adaugate aplicatii mobile pentru scanare cu telefonul in farmacie, trimiterea produselor catre magazine online,
                          etc. </p>                  
                    </div>                                        
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-light ">
                        <img className="card-img-top py-4" src="/img/softeh/configurabil.png" alt="Card" style={{ width: "3rem" }}></img><br/>                        
                            <h4 className="text-dark">Configurabil</h4>
                        <hr className="TextStyle"/>                        
                       <p style={{ textAlign: "justify" }} className="text-muted"> Framework-ul dezvoltat special pentru PlusERP permite un grad inalt de configurare conform 
                       necesitatilor partenerilor nostri.</p>                        
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/viteza.png" alt="Card" style={{ width: "3rem" }}></img><br/>
                        <h4 className="text-dark">Viteza la vanzare</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Am gandit fereastra de vanzare in asa fel incat sa ofere o viteza sporita si facilitati 
                        suplimentare pentru eficientizarea vanzarii (cum ar fi posibilitatea de a avea bonuri multiple concomitent; datorita interfetei noi este foarte simplu sa 
                        se deschida mai multe tab-uri in acealasi timp, lucru care scurteaza timpul de servire a clientilor). </p>                         
                    </div>
                    <div className="col-md-3 mt-4 text-center text-light">
                        <img className="card-img-top py-4" src="/img/softeh/istoric.png" alt="Card" style={{ width: "3rem" }}></img><br/>
                        <h4 className="text-dark">Vizualizare istoric</h4>
                        <hr className="TextStyle"/>
                        <p style={{ textAlign: "justify" }} className="text-muted">Optiunea de a vedea istoricul de achizitii al unui client daca acesta foloseste un card de 
                        fidelitate acordat de farmacie. </p>                  
                    </div>                                        
                </div>                                    
            </div>               
        </div>             
    );
};

export default Avantaje;