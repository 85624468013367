import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/user/Signup";
import Signin from "./pages/user/Signin";
import Home from "./pages/Home";
import Softeh from "./pages/softfarma/Softeh";
import SreviceIt from "./pages/consulting/ServiceIt";
import PrivateRoute from "./pages/auth/PrivateRoute";
import Dashboard from "./pages/user/UserDashboard";
import AdminRoute from "./pages/auth/AdminRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AddCategory from "./pages/admin/AddCategory";
import AddProduct from "./pages/admin/AddProduct";
//import Shop from "./core/Shop";
import Product from "./core/Product";
//import Cart from "./core/Cart";
//import Orders from "./admin/Orders";
import Profile from "./pages/user/Profile";
import CreateTiket from "./pages/user/tiket/CreateTiket";
import AllUserTikets from "./pages/user/tiket/AllUserTikets";
import MyTiket from "./pages/user/MyTiket";
import AdminTiket from "./pages/admin/tiket/AdminTiket";
import SNVM from "./pages/admin/Snvm";
import ManageProducts from "./pages/admin/ManageProducts";
import UpdateProduct from "./pages/admin/UpdateProduct";
import ManageCategory from "./pages/admin/ManageCategory";
import CompanyCreate from "./pages/super/company/CompanyCreate";
import WorkstationCreate from "./pages/super/company/WorkstationCreate";
import CeoCreate from "./pages/super/company/CeoCreate";
import SnvmClientCreate from "./pages/super/company/SnvmClientCreate";
import EmployeeCreate from "./pages/admin/company/EmployeeCreate";
import SnvmClientView from "./pages/super/company/SnvmClientView";
import HistoryTikets from "./pages/admin/tiket/HistoryTiket";
import ProgressTikets from "./pages/admin/tiket/ProgressTiket";
import ClientsForms from "./pages/super/company/NewClientOrder";
import PortofolioCRUD from "./pages/super/portofolio/create";
//import SuperDashboard from "./pages/admin/SuperDashboard";

//Total Blue System Route
import TotalRoute from "./components/auth/TotalRoute";
import TbDashboard from "./pages/tblue/TbDashboard";
import TbProductCreate from "./pages/tblue/CreateTbProduct";
import WiringCreate from "./pages/tblue/CreateWiring";
import AccessoryCreate from "./pages/tblue/CreateAccessory";
import OptionalCreate from "./pages/tblue/CreateOptional";


const Routes = () => {
    return (
        <>
            <ToastContainer />
            <Switch>
                <Route exact path="/" component={Home} /> 
                <Route exact path="/softeh" component={Softeh} /> 
                <Route exact path="/service" component={SreviceIt} />                
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/signup" component={Signup} />
                <PrivateRoute exact path="/user/dashboard" component={Dashboard} />
                <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
                <TotalRoute exact path="/tblue/dashboard" component={TbDashboard} />
                <TotalRoute exact path="/tblue/create/product" component={TbProductCreate} />
                <TotalRoute exact path="/tblue/create/accessory" component={AccessoryCreate} />
                <TotalRoute exact path="/tblue/create/wiring" component={WiringCreate} />
                <TotalRoute exact path="/tblue/create/optionals" component={OptionalCreate} />
                <AdminRoute exact path="/super/create/company" component={CompanyCreate} />
                <AdminRoute exact path="/super/create/workstation" component={WorkstationCreate} />
                <AdminRoute exact path="/super/contacts/clients" component={ClientsForms} />
                <Route exact path="/super/create/ceo" component={CeoCreate} />
                <Route exact path="/admin/create/employee" component={EmployeeCreate} />
                <AdminRoute exact path="/super/create/snvm" component={SnvmClientCreate} />
                <AdminRoute exact path="/super/view/snvm" component={SnvmClientView} />
                <AdminRoute exact path="/admin/snvm" component={SNVM} />
                <AdminRoute exact path="/admin/admintiket" component={AdminTiket} />
                <AdminRoute exact path="/history/tikets" component={HistoryTikets} />
                <AdminRoute exact path="/taken/tikets" component={ProgressTikets} />
                <PrivateRoute exact path="/user/create/tiket" component={CreateTiket} />
                <PrivateRoute exact path="/user/view/tiket" component={AllUserTikets} />
                <PrivateRoute exact path="/tiket/:tiketById" component={MyTiket} />
                <PrivateRoute exact path="/create/category" component={AddCategory} />
                <AdminRoute exact path="/create/product" component={AddProduct} />
                <Route path="/product/:productId" component={Product} />                
                <PrivateRoute exact path="/profile/:userId" component={Profile} />
                <PrivateRoute exact path="/admin/products" component={ManageProducts} />
                <PrivateRoute exact path="/admin/category" component={ManageCategory} />
                <AdminRoute exact path="/admin/product/update/:productId" component={UpdateProduct} />  
                <AdminRoute exact path="/super/portofolio/create" component={PortofolioCRUD}  />     
            </Switch>
        </>
    );
};

export default Routes;
