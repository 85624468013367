import React, { useState, useEffect } from "react";
import Layout from "../../../core/Layout";
import AdminNav from "../../../components/nav/AdminNav";
import LocalSearch from "../../../components/forms/LocalSearch";
import { getContacts, removeContact } from "../../../functions/contact";
import { isAuthenticated } from "../../../components/auth";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const ClientViewOrder = () => {   
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user, token } = isAuthenticated();
    const [keyword, setKeyword] = useState("");
    
    const loadContacts = () => {
        getContacts(user._id, token).then((s) => setContacts(s.data));
    };
        

    useEffect(() => {
        loadContacts();        
    }, []);

    const handleRemove = async (slug) => {
        // let answer = window.confirm("Delete?");
        // console.log(answer, slug);
        if (window.confirm("Delete?")) {
          setLoading(true);
          removeContact(slug, user._id, token)
            .then((res) => {
              setLoading(false);
              toast.error(`${res.data.name} deleted`);
              loadContacts();
            })
            .catch((err) => {
              if (err.response.status === 400) {
                setLoading(false);
                toast.error(err.response.data);
              }
            });
        }
      };

    const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);
   
    return (
        <Layout            
        description={`Buna ziua ${user.name}, aici poti sa vizualizezi posibili clienti noi.`}           
        >            
            <div className="">
                <div className="row" style={{ minHeight: '75vh' }}>
                    <div className="col-md-3">
                        <AdminNav />
                    </div>
                    <div className='col-md-8'>
                        {loading ? (
                            <LoadingOutlined className="text-danger h1" />
                            ) : (
                            <h4>New Client Forms</h4>
                            )}
                        <hr />                                
                            <LocalSearch keyword={keyword} setKeyword={setKeyword} />                            
                            {contacts.filter(searched(keyword)).map((c) => (
                                <div className="alert alert-secondary" key={c._id}>
                                    <span className="text-primary">{c.name}</span> doreste <span className="text-primary">{c.services}</span>, contact la email:<em> {c.email} </em>
                                    <span
                                    onClick={() => handleRemove(c.slug)}
                                    className="btn btn-sm float-right"
                                    >
                                    <DeleteOutlined className="text-danger" />
                                    </span>                                   
                                </div>
                            ))}
                                  
                    </div>
                </div>
            </div>           
        </Layout>
    );
};

export default ClientViewOrder;