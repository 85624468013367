import React from "react";
import { Button } from 'antd';


const PlusErp = () => {    

  
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4 bg-light">
            <h1 className="text-title-blue text-center mt-4">PLUS ERP NetFarm</h1> 
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-4 text-center" >                        
                    <img className="card-img-top" src="/img/softeh/netfarm-Plus-450x418.png" alt="SoftehPlus" ></img><br/> 
                    <em className="text-muted mt-4">“Un PLUS pentru Farmaciile de Circuit Deschis”</em>
                    </div>                  
                   
                    <div className="col-md-8 mt-4 text-center text-muted h6" >            
                        <p style={{ textAlign: "justify" }}><strong className="h5 text-primary">Solutie </strong>  informatica pentru evidenta cantitativ-valorica a medicamentelor si a produselor 
                        parafarmaceutice din farmaciile de circuit deschis. Solutie foarte apreciata de lanturile de farmacii, customizabila si adaptata tehnologiilor actuale – Cloud, Web, SaaS – 
                        care elimina necesitatea existentei unui modul de replicare de date, permitand astfel lanturilor de farmacii sa lucreze centralizat, intr-o singura baza de date.</p>
                        <p style={{ textAlign: "justify" }}><strong className="h5 text-primary">PlusERP</strong>  este rezultatul experientei de peste 20 de ani a companiei Softeh Plus pe piata sistemelor
                         de gestiune pentru farmacii fiind prezent la peste 2500 de clienti la nivel national. Alaturi de clientii nostri am creat un sistem robust, intr-o tehnologie moderna, care sa
                          raspunda cerintelor actuale ale pietei. Poate fi implementat ca solutie SaaS cu baza de date gazduita in cloud (AWS, Microsoft Azzure etc.) in data centere specializate, 
                          fara a mai fi necesare resurse hardware si de intretinere proprii. PlusERP este dezvoltat in tehnologie Microsoft, permitand deservirea la acelasi nivel de calitate ridicata, 
                          atat a clientilor mici cat si a lanturilor de farmacii.</p>
                        <div className="float-left mt-4">
                            <a className="btn btn-info btn-md active mt-4" href="https://softeh.ro/farmacii/circuit-deschis" target="blank" role="button">Vezi mai multe detalii</a>                            
                        </div>
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default PlusErp;