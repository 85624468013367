import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../auth";
import { Redirect } from "react-router-dom";
import { read, update, updateUser } from "./apiUser";
import UserNav from "../../components/nav/UserNav";

const Profile = ({ match }) => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
         phone: "",
        farmacie: "",
        plucru: "",
        error: false,
        success: false
    });

    const { token } = isAuthenticated();
    const { name, email, password, phone, farmacie, plucru, error, success } = values;

    const init = userId => {
        // console.log(userId);
        read(userId, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: true });
            } else {
                setValues({ ...values, name: data.name, email: data.email, phone: data.phone, farmacie: data.farmacie, plucru: data.plucru });
            }
        });
    };

    useEffect(() => {
        init(match.params.userId);
    }, []);

    const handleChange = name => e => {
        setValues({ ...values, error: false, [name]: e.target.value });
    };

    const clickSubmit = e => {
        e.preventDefault();
        update(match.params.userId, token, { name, email, password, phone, farmacie, plucru, }).then(
            data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    updateUser(data, () => {
                        setValues({
                            ...values,
                            name: data.name,
                            email: data.email,
                            phone: data.phone,
                            company: data.company,
                            workstation: data.workstation,
                            success: true
                        });
                    });
                }
            }
        );
    };

    const redirectUser = success => {
        if (success) {
            return <Redirect to="/user/dashboard" />;
        }
    };

    const profileUpdate = (name, email, password, phone, company, workstation) => (
        <form>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    type="text"
                    onChange={handleChange("name")}
                    className="form-control"
                    value={name}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input
                    type="email"
                    onChange={handleChange("email")}
                    className="form-control"
                    value={email}
                />
            </div>
             <div className="form-group">
                <label className="text-muted">Phone</label>
                <input
                    onChange={handleChange("phone")}
                    type="text"
                    className="form-control"
                    value={phone}
                />
            </div>            
            <div className="form-group">
                <label className="text-muted">Password</label>
                <input
                    type="password"
                    onChange={handleChange("password")}
                    className="form-control"
                    value={password}
                />
            </div>

            <button onClick={clickSubmit} className="btn btn-primary">
                Submit
            </button>
        </form>
    );

    return (
        <Layout
            title="Profile"
            description="Update your profile"
            className="container-fluid"
        >
             <div className="row" style={{ minHeight: '80vh' }}>
                <div className="col-md-3">
                    <UserNav />
                </div>
                <div className="col-md-9 ">  
            <h2 className="mb-4">Profile update</h2>
            {profileUpdate(name, email, password, phone, farmacie, plucru)}
            {redirectUser(success)}
            </div>
            </div>
        </Layout>
    );
};

export default Profile;
