import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AdminNav from "../../../components/nav/AdminNav";
import Layout from "../../../core/Layout";
import { signup } from "../../../components/auth";
import EmployeeCreateForm from "../../../components/forms/EmployeeCreateForm";
import { getCompanys, getCompanyWork, getCompanyRelated } from "../../../functions/company";
import {  getWorkRelated } from "../../../functions/workstation";
import { LoadingOutlined } from "@ant-design/icons";
import { isAuthenticated } from "../../../components/auth";


const initialState = {
    name: "",
    email: "",
    password: "",
    cname: "",
    phone: "",    
    company: "",    
    workstation: "",
    wname: ""
  };

const EmployeeCreate = () => {
    const [values, setValues] = useState(initialState); 
    const [workstations, setWorkstations] = useState([]);
    const [companys, setCompanys] = useState([]); 
    const [showWork, setShowWork] = useState(false);
    const [loading, setLoading] = useState(false); 
    
    const { name, email, password, phone, company, cname, wname, workstation } = values;
    

    useEffect(() => {
        loadCompanys();       
      }, []);
    
    const loadCompanys = () =>
        getCompanys().then((c) => setCompanys(c.data));
    
         
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        // console.log(e.target.name, " ----- ", e.target.value);
      };

    const handleCompanyChange = (e) => {
        e.preventDefault();
        console.log("CLICKED COMPANY", e.target.value);
        getCompanyRelated(e.target.value).then((c) => setValues({...values, company: c.data._id, cname: c.data.title}));       
        getCompanyWork(e.target.value).then((res) => {
          console.log("Workstation by company", res.data);
          setWorkstations(res.data);
        });
        setShowWork(true);        
      };

    const handleWorkstationChange = (e) => {
        e.preventDefault();
        console.log("CLICKED Workstation", e.target.value);
        getWorkRelated(e.target.value).then((c) => setValues({...values, workstation: c.data._id, wname: c.data.title}));                    
      };

    
    const handleSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });
        setLoading(true);
        signup({ name, email, password, phone, company, cname, wname, workstation }).then(data => {
            if (data.error) {
                toast.error(data.error);
                setLoading(false);
            } else {
                setValues({
                    ...values,
                    name: "",
                    email: "",
                    password: "",
                    phone: "",
                    company: "",
                    cname: "",
                    wname: "",
                    workstation: "",                  
                });
                setLoading(false);
                toast.success(`"${name}" is created`);
            }
        });
    };

    
    

    return (
        <Layout            
        description={`Buna ziua ${isAuthenticated() && isAuthenticated().user.name} , aici poti sa creezi un punct de lucru.`}           
        >            
                <div className="">
                    <div className="row" style={{ minHeight: '75vh' }}>
                        <div className="col-md-3">
                            <AdminNav />
                        </div>

                        <div className="col-md-9">
                        {loading ? (
                            <LoadingOutlined className="text-danger h1" />
                            ) : (
                            <h4>Employee create</h4>
                            )}
                            <hr />
                            <EmployeeCreateForm
                                handleSubmit={handleSubmit}
                                handleChange={handleChange}
                                handleCompanyChange={handleCompanyChange}
                                handleWorkstationChange={handleWorkstationChange}
                                setValues={setValues}
                                values={values}
                                companys={companys}
                                workstations={workstations}
                                showWork={showWork}
                            />
                        </div>
                    </div> 
                </div>            
        </Layout>
    );
};

export default EmployeeCreate;
