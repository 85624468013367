import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TopPage = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid TextStyle mt-5">
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-5 mt-4 text-center  text-muted" >                        
                    <img className="card-img-top py-4" src="/img/softeh/plus.png" alt="SoftehPlus" style={{ width: "10rem" }}></img> 
                    <h5 className="text-dark mt-4">Sistem informatic complet pentru zona de farmacii si retail</h5>
                    <p style={{ textAlign: "justify" }}><strong className="h5">PlusERP </strong> este rezultatul experientei de peste 19 ani a companiei  PlusERP este dezvoltat in tehnologie Microsoft, permitand deservirea 
                        la acelasi nivel de calitate ridicata atat a clientilor mici cat si a lanturilor de magazine sau farmacii.</p>
                        <p style={{ textAlign: "justify" }}><strong className="h5">Softeh Plus</strong> pe piata sistemelor de gestiune pentru farmacii si magazine si peste 4000 de 
                        clienti la nivel national. Alaturi de clientii nostri am creat un sistem  de vanzari robust, intr-o tehnologie moderna, care sa raspunda cerintelor actuale ale pietei. </p>
                        <p style={{ textAlign: "justify" }}><strong className="h5"> PlusERP </strong>este dezvoltat in tehnologie Microsoft, permitand deservirea la acelasi nivel de 
                        calitate ridicata atat a clientilor mici cat si a lanturilor de magazine sau farmacii.</p>                       
                    </div>                  
                   
                    <div className="col-md-5 mt-4 text-center  text-dark" >                        
                        <h4>DE CE NOI?
                            <hr />
                        </h4>
                        <Tabs onChange={callback} type="card" className="TextStyle text-muted mt-4">
                        <TabPane className="TextStyle text-muted h5" tab="Asistenta tehnica IT" key="1" style={{ textAlign: "justify" }}>
                        <p>Asistenta tehnica incepe cu receptionarea apelului telefonic, sau receptionarea formularului completat online. Fiecarui incident de asistenta tehnica i se elibereaza un tichet unic de gestiune a 
                        apelului/formularului prin intermediul aplicatiei, in prima faza, incercandu-se rezolvarea pe loc a problemei dumneavoastra.</p>
                        <p style={{ textAlign: "justify" }}>Cum lucram:</p>
                        <ul style={{ textAlign: "justify" }}>
                            <li>Receptionarea evenimentului</li>
                            <li>Diagnoza si Asistenta</li>                            
                            <li>Rezolvarea evenimentului telefonic / remote</li>
                            <li>Inchidere si raportare eveniment</li>                            
                        </ul>
                        </TabPane>
                        <TabPane className="TextStyle text-muted" tab="Asistenta tehnica SNVM" key="2" style={{ textAlign: "justify" }}>
                            <p>Incepand cu februarie 2019 a intrat in vigoare si produce efecte Regulamentul delegat (UE) 2016/161 al Comisiei Europene care este direct 
                                aplicabil in legislatiile nationale ale statelor UE. In Romania, Organizatia de Serializare a Medicamentelor din Romania (OSMR) este organismul 
                                responsabil pentru implementarea si administrarea Sistemului National de Verificare a Medicamentelor (SNVM)</p>
                                <p >Ce oferim noi:</p>
                        <ul >
                            <li>să asiguram suport in scop de administrare cont SNVM,  in vederea  intermedierii relatiei dintre beneficiar si platforma administrata de OSMR, 
                                inclusiv gestionarea solicitarilor punctuale ale beneficiarului (transmise Executantului prin administratorul contului SNVM alocat intern de 
                                catre beneficiar). </li>
                            <li>să asiguram evidența solicitarilor făcute de beneficiar și sa-i comunice acestuia, la cerere, statusul rezolvarii sesizarilor  sau / si sa 
                                confirme inchiderea lor, ca urmare a rezolvarii. Solicitarile pot fi realizate în timpul programului de lucru al Executantului, de luni până vineri, între orele 9-17;</li>                            
                            <li>sa oferim asistenta si suport pentru reinstalarea certificatelor GLN ori de cate ori este necesar, pe intreaga perioada contractuala;</li>
                                                       
                        </ul>
                        </TabPane>                        
                    </Tabs>
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default TopPage;