import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../core/Layout";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../../components/auth";
import { createSNVM, getSNVMs, removeSNVM } from "../../../functions/snvm";
import { getCompanys, getCompanyWork, getCompanyRelated } from "../../../functions/company";
import { getWorkstations, getWorkRelated } from "../../../functions/workstation";
import SnvmClientCreateForm from "../../../components/forms/SnvmClientCreateForm";
import LocalSearch from "../../../components/forms/LocalSearch";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";



import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
  company: "",    
  workstation: "",
  adminuser: "",
  lufuser: "",   
  description: "",
  resetpass: "",  
};

const SnvmCreate = () => {
  const [values, setValues] = useState(initialState);  
  const [loading, setLoading] = useState(false);
  const [snvms, setSnvms] = useState([]);  
  const [companys, setCompanys] = useState([]);
  const [workstations, setWorkstations] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [showWork, setShowWork] = useState(false);

  const { user, token } = isAuthenticated();

  useEffect(() => {
    loadSnvms();
    loadCompanys();
    loadWorksations();
  }, []);

  const loadSnvms = () =>
    getSNVMs().then((s) => setSnvms(s.data));

    const loadCompanys = () =>
        getCompanys().then((c) => setCompanys(c.data));

    const loadWorksations = () =>
        getWorkstations().then((w) => setWorkstations(w.data));


  const handleSubmit = (e) => {
    e.preventDefault();
    createSNVM(values, user._id, token)
      .then((res) => {
        console.log(res);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status === 400) toast.error(err.response.data);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  const handleCompanyChange = (e) => {
    e.preventDefault();
    console.log("CLICKED COMPANY", e.target.value);
    getCompanyRelated(e.target.value).then((c) => setValues({ ...values, company: c.data.title }));       
    getCompanyWork(e.target.value).then((res) => {
      console.log("Workstation by company", res.data);
      setWorkstations(res.data);
    });
    setShowWork(true);        
  };

  const handleWorkstationChange = (e) => {
    e.preventDefault();
    console.log("CLICKED Workstation", e.target.value);
    getWorkRelated(e.target.value).then((c) => setValues({...values, workstation: c.data.title}));                    
  };

  const handleRemove = async (slug) => {
    // let answer = window.confirm("Delete?");
    // console.log(answer, slug);
    if (window.confirm("Delete?")) {
      setLoading(true);
      removeSNVM(slug, user._id, token)
        .then((res) => {
          setLoading(false);
          toast.error(`${res.data.name} deleted`);
          loadSnvms();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };

  const searched = (keyword) => (c) => c.company.toLowerCase().includes(keyword);


  return (
    <Layout      
      description={`Buna ziua ${user.name}, aici poti sa creezi un client nou pentru OSMR.`}
      >      
      <div className="">
        <div className="row" style={{ minHeight: '75vh' }}>
          <div className="col-md-3" >
            <AdminNav />
          </div>

          <div className="col-md-9">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Create new SNVM client</h4>
            )}
            <hr />
            <SnvmClientCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleCompanyChange={handleCompanyChange}
              handleWorkstationChange={handleWorkstationChange}
              setValues={setValues}
              values={values}    
              companys={companys}   
              workstations={workstations}     
              showWork={showWork}
            />

            <LocalSearch keyword={keyword} setKeyword={setKeyword} />

            {/* step 5 */}
            {snvms.filter(searched(keyword)).map((c) => (
              <div className="alert alert-secondary" key={c._id}>
                {c.company} - {c.workstation}
                <span
                  onClick={() => handleRemove(c.slug)}
                  className="btn btn-sm float-right"
                >
                  <DeleteOutlined className="text-danger" />
                </span>
                <Link to={`/admin/effect/${c.slug}`}>
                  <span className="btn btn-sm float-right">
                    <EditOutlined className="text-warning" />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>      
    </Layout>
  );
};

export default SnvmCreate;
