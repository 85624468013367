import axios from "axios";
import { API } from "../config";


export const createWorkstation = async (product, userId, token) =>
  await axios.post(`${API}/workstation/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeWorkstation = async (slug, userId, token) =>
  await axios.delete(`${API}/workstation/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getWorkstation = async (slug) =>
  await axios.get(`${API}/workstation/${slug}`);

export const updateWorkstation = async (slug, workstation, token) =>
  await axios.put(`${API}/workstation/${slug}`, workstation, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getWorkstations = async () =>
  await axios.get(`${API}/workstations`);

export const getWorkRelated = async (workstationId) =>
  await axios.get(`${API}/workstation/related/${workstationId}`);