import React, { useState, useEffect } from "react";
import Card from "../../../components/card/TiketUserCard";
import { isAuthenticated } from "../../../components/auth";
import Layout from "../../../core/Layout";
import { getTiketUserAll } from "../../../functions/tiket";
import { getCompany } from "../../../functions/company";
import { getWorkstation } from "../../../functions/workstation";
import UserNav from "../../../components/nav/UserNav";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import LocalSearch from "../../../components/forms/LocalSearch";

const ActiveTiket = () => {        
    const { user, token } = isAuthenticated();    
    const [tikets, setTikets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
     

    const loadTiket = () => {   
        setLoading(true);
        getTiketUserAll(user._id, token, user).then(t => {
            if (t.data.error) {
                setLoading(false);                
            } else {        
                setLoading(false);        
                setTikets(t.data);                          
            }
        });
    };     


    useEffect(() => {
        loadTiket();        
    }, []);

    const searched = (keyword) => (c) => c.tiketid.toLowerCase().includes(keyword);
 
 return (
        <Layout            
            description={`Buna ziua ${user.name}.`}
            className="container-fluid"
        >
            <div className="row" style={{ minHeight: '80vh' }}>
                <div className="col-md-3">
                    <UserNav />
                </div>
            
                <div className="col-md-9 ">  
                    {loading ? (
                        <LoadingOutlined className="text-danger h1" />
                        ) : (
                        <h4>Arhiva sesizari</h4>
                        )}
                    <hr />                   
                    <LocalSearch keyword={keyword} setKeyword={setKeyword} />
                    
                        {tikets.filter(searched(keyword)).map((c) => (
                            <div className="alert alert-secondary" key={c._id}>
                               Tiket ID: {c.tiketid} - {c.observation} 
                            <span className="float-right">
                                {c.status} | {c.createdAt} 
                            </span>
                
                            </div>
                        ))}
                               
                </div>
            </div>
        </Layout>
    );
};

export default ActiveTiket;