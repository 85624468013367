import axios from "axios";
import { API } from "../../config";

// Product
export const createTbProduct = async (product, userId, token) =>
  await axios.post(`${API}/tbproduct/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeTbProduct = async (slug, userId, token) =>
  await axios.delete(`${API}/tbproduct/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTbProduct = async (slug) =>
  await axios.get(`${API}/tbproduct/${slug}`);


export const updateTbProduct = async (slug, userId, product, token) =>
  await axios.put(`${API}/tbproduct/${slug}/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTbProducts = async () =>
  await axios.get(`${API}/tbproducts`);

export const getTbProductById = async (productId) =>
  await axios.get(`${API}/tbproduct/related/${productId}`);

export const getTbProductAccessory = async (_id) =>
  await axios.get(`${API}/tbproduct/accessory/${_id}`);


//Accessory
export const createAccessory = async (product, userId, token) =>
  await axios.post(`${API}/accessory/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeAccessory = async (slug, userId, token) =>
  await axios.delete(`${API}/accessory/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getAccessory = async (slug) =>
  await axios.get(`${API}/accessory/${slug}`);


export const updateAccessory = async (slug, userId, product, token) =>
  await axios.put(`${API}/accessory/${slug}/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getAccessorys = async () =>
  await axios.get(`${API}/accessorys`);

export const getAccessoryByProduct = async (_id) =>
  await axios.get(`${API}/accessory/product/${_id}`);

export const getAccessoryById = async (productId) =>
  await axios.get(`${API}/accessory/related/${productId}`);

//Wiring and Mounting
export const createWiring = async (product, userId, token) =>
  await axios.post(`${API}/wiring/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeWiring = async (slug, userId, token) =>
  await axios.delete(`${API}/wiring/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getWiring = async (slug) =>
  await axios.get(`${API}/wiring/${slug}`);


export const updateWiring = async (slug, userId, product, token) =>
  await axios.put(`${API}/wiring/${slug}/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getWiringByProduct = async (_id) =>
  await axios.get(`${API}/wiring/product/${_id}`);

export const getWirings = async () =>
  await axios.get(`${API}/wirings`);

export const getWiringById = async (productId) =>
  await axios.get(`${API}/wiring/related/${productId}`);

// Optional
export const createOptional = async (product, userId, token) =>
  await axios.post(`${API}/optional/create/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const removeOptional = async (slug, userId, token) =>
  await axios.delete(`${API}/optional/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getOptional = async (slug) =>
  await axios.get(`${API}/optional/${slug}`);


export const updateOptional = async (slug, userId, product, token) =>
  await axios.put(`${API}/optional/${slug}/${userId}`, product, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getOptionals = async () =>
  await axios.get(`${API}/optionals`);



