import React from "react";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../auth";
import ActiveTiket from "./tiket/ActiveTiket";
import UserNav from "../../components/nav/UserNav";

const Dashboard = () => {    
    
  const userActiveTikets = () => {
        return (
            <div className="" >
                <h3 >Sesizari Active</h3>                 
                <div>               
                    <ActiveTiket  />                    
                </div>
            </div>
        );
    };

    return (
        <Layout            
            description={`Salut ${isAuthenticated() && isAuthenticated().user.name}!`}
            className="container-fluid"
        >
            <div className="row" style={{ minHeight: '80vh' }}>
                <div className="col-md-3">                    
                    <UserNav />                    
                </div>
                <div className="col-md-9">
                    {userActiveTikets()}                
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
