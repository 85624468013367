import React from "react";
import { Link, Redirect } from "react-router-dom";
import { isAuthenticated } from "../../components/auth";
import { Slider, Menu } from 'antd';
import {
  DesktopOutlined,
  InsertRowBelowOutlined,
  DatabaseOutlined,  
  FolderViewOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

const { SubMenu, Item } = Menu;

const redirectUser = () => {
  if(isAuthenticated){
    const { user  } = isAuthenticated();
}else{
  return <Redirect to="/signin" />
}};

const TbNav = () => (
  <Menu mode="inline" theme="light" >  
  <img src="/img/logo2.png" style={{ height: "50px", width: "100px" }} className="container col-md-8 offset-md-4" />
  <hr />
  <Item className="" icon={<DesktopOutlined />}>
    <Link to="/tblue/dashboard">
          Dashboard
    </Link>
  </Item>

  <SubMenu
          icon={<DatabaseOutlined />}
          title="Product resource"          
        >
    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/tblue/create/product" >
            Product
        </Link>
    </Item>

    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/tblue/create/accessory" >
            Accessory
        </Link>
    </Item>

    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/tblue/create/wiring" >            
            Wiring end Mounting
        </Link>
    </Item> 

    <Item className="" icon={<InsertRowBelowOutlined />}>
        <Link to="/tblue/create/optionals" >            
            Optionals
        </Link>
    </Item> 
  </SubMenu>

    
</Menu>
);

export default TbNav;
