import React from "react";
import { Select } from "antd";

const { Option } = Select;

const PortofolioCreateForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  photo,
  setPhoto,
}) => {
  // destructure
  const {
    title,    
    img,
    category,
    tehnology, 
    plink, 
  } = values;
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mt-4">
            <div className="form-group">
              <label>Titlu</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={title}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
            <label>Categorie Proiect</label>
            <input
              type="text"
              name="category"
              className="form-control"
              value={category}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
              <label>Tehnologie Proiect</label>
              <input
                type="text"
                name="tehnology"
                className="form-control"
                value={tehnology}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label>Link Proiect</label>
              <input
                type="text"
                name="plink"
                className="form-control"
                value={plink}
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="col-md-4">
          {photo && (
              <div className="text-center">
                <img
                  src={URL.createObjectURL(photo)}
                  alt="product photo"
                  className="img img-responsive"
                  height="200px"
                />
              </div>
            )}
            <br/>
          <div className="form-group">          
            <label className="btn btn-outline-secondary col-12 mb-3">
                {photo ? photo.name : "Upload photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
          </div>           
        </div>
        <br />
        <button className="btn btn-outline-info">Save</button>
      </div>
    </form>
  );
};

export default PortofolioCreateForm;
