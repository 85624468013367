import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const AvantajeWeb = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid py-4 TextStyle" >   
            <h2 className="text-dark text-center">Avantaje</h2>         
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mt-4 text-center text-muted ">
                        <img className="card-img-top py-4" src="/img/webdev.png" alt="Card" style={{ width: "10rem" }}></img><br/>                       
                            
                                                   
                       <ul style={{ textAlign: "justify" }}>
                       <p className="text-primary h6">Serviciile noastre de dezvoltare web includ:</p>
                            <li>HTML5 și CSS3 optimizate</li>
                            <li>Site-uri web responsive</li>                            
                            <li>Frontend - React JS, Angular </li>
                            <li>Backend - Node JS, ASP.NET</li>  
                            <li>Baze de date - MongoDB, Microsoft SQL</li> 
                            <li>Limbaje - Java script, C#</li>                         
                        </ul>                                              
                    </div>
                    <div className="col-md-3 mt-4 text-center text-muted">
                        <img className="card-img-top py-4" src="/img/webdes.png" alt="Card" style={{ width: "10rem" }}></img><br/>                                                
                        <ul style={{ textAlign: "justify" }}>
                        <p className="text-primary h6">Serviciul nostru de design web combină:</p>
                            <li>Planificare extinsă și analiză a concurenților</li>
                            <li>Procesul de proiectare axat pe client</li>                            
                            <li>Atenție obsesivă la detalii </li>
                            <li>Aplicarea perspectivelor strategice și de marketing pentru design-uri</li>   
                            <li>Flux de lucru și procese de proiectare bazate pe rezultate</li>                         
                        </ul>                                               
                    </div>
                    <div className="col-md-3 mt-4 text-center text-muted">
                        <img className="card-img-top py-4" src="/img/cms.png" alt="Card" style={{ width: "10rem" }}></img><br/>                                                
                        <ul style={{ textAlign: "justify" }}>
                        <p className="text-primary h6">Site-urile noastre web oferă un control incredibil asupra designului, conținutului și structurii, inclusiv:</p>
                            <li>Mai multe site-uri web controlate de un singur CMS</li>
                            <li>Site-uri multilingve / multi-țări</li>                            
                            <li>Permisiuni de utilizator foarte granulare</li>
                            <li>Versiunea și fluxul de lucru al conținutului editare front-end</li>                                                 
                        </ul>                                                             
                    </div>                                        
                </div>                                                
            </div>               
        </div>             
    );
};

export default AvantajeWeb;