import axios from "axios";
import { API } from "../config";


export const createTiket = async ( userId, token, tiket) =>
  await axios.post(`${API}/tiket/create/${userId}`, tiket, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}` 
    },
  });

export const removeTiket = async (slug, userId, token) =>
  await axios.delete(`${API}/tiket/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTiket = async (slug) =>
  await axios.get(`${API}/tiket/${slug}`);

export const updateTiket = async (slug, tiket, token) =>
  await axios.put(`${API}/tiket/${slug}`, tiket, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTikets = async () =>
  await axios.get(`${API}/tikets`);

export const getTiketUserId = async (userId, token, email) => 
  await axios.put(`${API}/tiket/${userId}`, email, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

  export const getTiketUserAll = async (userId, token, email) => 
  await axios.put(`${API}/tiket/all/${userId}`, email, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTiketActive = async (userId, token) => 
  await axios.get(`${API}/tiket/list/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getTiketProgress = async (userId, token) => 
  await axios.get(`${API}/tiket/progress/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });


export const updatePassReset = async (slug, userId, token, snvm) =>
  await axios.put(`${API}/snvm/${slug}/reset/${userId}`, snvm, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

  export const getStatusValues = (userId, token) => {
    return fetch(`${API}/tiket/status-values/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateTiketStatus = (userId, token, tiketId, status) => {
    return fetch(`${API}/tiket/${tiketId}/status/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ status, tiketId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const listTikets = (userId, token) => {
  return fetch(`${API}/tiket/list/${userId}`, {
      method: "GET",
      headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
      }
  })
      .then(response => {
          return response.json();
      })
      .catch(err => console.log(err));
};