import React from "react";
import AdminNav from "../../components/nav/AdminNav";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../../components/auth";
import AdminTiket from "./tiket/AdminTiket";

const AdminDashboard = () => {

  const {
    user: { name, email, role }
} = isAuthenticated();

const adminActiveTikets = () => {
  return (
      <div className="">
          <h3 className="">Sesizari Active</h3>                 
        <div className="">               
            <AdminTiket  />              
        </div>
      </div>
  );
};


  return (
    <Layout     
    description={`G'day ${name}! `}
  >    
    <div className="row" style={{ minHeight: '80vh' }}>
      <div className="col-md-3">
        <AdminNav />
      </div>
      <div className="col-md-9">      
      {adminActiveTikets()}
      </div>
    </div>    
  </Layout>
  );
};

export default AdminDashboard;
