import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../auth";
import { Redirect } from "react-router-dom";
import { getProduct, getCategories, updateProduct } from "./apiAdmin";

const UpdateProduct = ({ match }) => {
    const [values, setValues] = useState({
        plucru: "",
        description: "",       
        categories: [],
        category: "",
        adminuser: "",
        lufuser: "",        
        resetpass: "",
        photo: "",
        loading: false,
        error: "",
        createdProduct: "",
        redirectToProfile: false,
        formData: ""
    });

    const { user, token } = isAuthenticated();
    const {
       plucru,
        description,        
        categories,        
        adminuser,
        lufuser,
        resetpass,
        loading,
        error,
        createdProduct,
        redirectToProfile,
        formData
    } = values;

    const init = productId => {
        getProduct(productId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                // populate the state
                setValues({
                    ...values,
                    plucru: data.plucru,
                    description: data.description,
                    adminuser: data.adminuser,
                    category: data.category._id,
                    lufuser: data.lufuser,                   
                    resetpass: data.resetpass,
                    formData: new FormData()
                });
                // load categories
                initCategories();
            }
        });
    };

    // load categories and set form data
    const initCategories = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    categories: data,
                    formData: new FormData()
                });
            }
        });
    };

    useEffect(() => {
        init(match.params.productId);
    }, []);

    const handleChange = name => event => {
        const value =
            name === "photo" ? event.target.files[0] : event.target.value;
        formData.set(name, value);
        setValues({ ...values, [name]: value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        updateProduct(match.params.productId, user._id, token, formData).then(
            data => {
                if (data.error) {
                    setValues({ ...values, error: data.error });
                } else {
                    setValues({
                        ...values,
                        plucru: "",
                        description: "",
                        photo: "",
                        adminuser: "",
                        lufuser: "",
                        resetpass: "",
                        loading: false,
                        error: false,
                        redirectToProfile: true,
                        createdProduct: data.name
                    });
                }
            }
        );
    };

    const newPostForm = () => (
        <form className="mb-3" onSubmit={clickSubmit}>
            <h4>Post Photo</h4>
            <div className="form-group">
                <label className="btn btn-secondary">
                    <input
                        onChange={handleChange("photo")}
                        type="file"
                        name="photo"
                        accept="image/*"
                    />
                </label>
            </div>

            <div className="form-group">
                <label className="text-muted">Punct de lucru</label>
                <input
                    onChange={handleChange("name")}
                    type="text"
                    className="form-control"
                    value={plucru}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Descriere</label>
                <textarea
                    onChange={handleChange("description")}
                    className="form-control"
                    value={description}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Admin User</label>
                <input
                    onChange={handleChange("adminuser")}
                    type="text"
                    className="form-control"
                    value={adminuser}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">LUF User</label>
                <input
                    onChange={handleChange("lufuser")}
                    type="text"
                    className="form-control"
                    value={lufuser}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Farmacie</label>
                <select
                    onChange={handleChange("category")}
                    className="form-control"
                >
                    <option>Selecteaza</option>
                    {categories &&
                        categories.map((c, i) => (
                            <option key={i} value={c._id}>
                                {c.name}
                            </option>
                        ))}
                </select>
            </div>
             
            <div className="form-group">
                <label className="text-muted">Reset LUF Password</label>
                <input
                    onChange={handleChange("quantity")}
                    type="number"
                    className="form-control"
                    value={resetpass}
                />
            </div>

            <button className="btn btn-outline-primary">Salveaza</button>
        </form>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: createdProduct ? "" : "none" }}
        >
            <h2>{`${createdProduct}`} este modificat!</h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Asteapta...</h2>
            </div>
        );

    const redirectUser = () => {
        if (redirectToProfile) {
            if (!error) {
                return <Redirect to="/admin/products" />;
            }
        }
    };

    return (
        <Layout
            title="Modificare produse existente"
            description={`Salut ${user.name}, aici poti modifica produsele existente`}
        >
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                    {newPostForm()}
                    {redirectUser()}
                </div>
            </div>
        </Layout>
    );
};

export default UpdateProduct;
