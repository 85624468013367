import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../core/Layout";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../../components/auth";
import { createCompany, getCompanys, removeCompany } from "../../../functions/company";
import ProductCreateForm from "../../../components/forms/CompanyCreateForm";
import LocalSearch from "../../../components/forms/LocalSearch";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";


import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
  title: "",    
  cif: "",
  order: "",
  bank: "", 
  bankaccount: "",
  adress: "",
  city: "",
  region: "",
  description: "",
  email: "",  
};

const CompanyCreate = () => {
  const [values, setValues] = useState(initialState);  
  const [loading, setLoading] = useState(false);
  const [companys, setCompanys] = useState([]);  
  const [keyword, setKeyword] = useState("");

  const { user, token } = isAuthenticated();

  useEffect(() => {
    loadCompanys();
  }, []);

  const loadCompanys = () =>
    getCompanys().then((c) => setCompanys(c.data));


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createCompany(values, user._id, token)
      .then((res) => {
        setLoading(false);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        // if (err.response.status === 400) toast.error(err.response.data);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  const handleRemove = async (slug) => {
    // let answer = window.confirm("Delete?");
    // console.log(answer, slug);
    if (window.confirm("Delete?")) {
      setLoading(true);
      removeCompany(slug, user._id, token)
        .then((res) => {
          setLoading(false);
          toast.error(`${res.data.name} deleted`);
          loadCompanys();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };

  const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);
  

  return (
    <Layout      
      description={`Buna ziua ${user.name}, aici poti sa creezi o companie noua.`}
      >      
      <div className="">
        <div className="row" style={{ minHeight: '80vh' }}>
          <div className="col-md-3">
            <AdminNav />
          </div>

          <div className="col-md-9">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Company create</h4>
            )}
            <hr />
            <ProductCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}            
            />

            <LocalSearch keyword={keyword} setKeyword={setKeyword} />

            {/* step 5 */}
            {companys.filter(searched(keyword)).map((c) => (
              <div className="alert alert-secondary" key={c._id}>
                {c.title}
                <span
                  onClick={() => handleRemove(c.slug)}
                  className="btn btn-sm float-right"
                >
                  <DeleteOutlined className="text-danger" />
                </span>
                <Link to={`/admin/effect/${c.slug}`}>
                  <span className="btn btn-sm float-right">
                    <EditOutlined className="text-warning" />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>      
    </Layout>
  );
};

export default CompanyCreate;
