import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";
import Card from "../../core/Card";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { getTiket} from "./apiUser";


const MyTiket = () => {        
    const { user, token } = isAuthenticated();
    const [error, setError] = useState(false);
    const [tikets, setTikets] = useState([]);


    const goBack = () => (
        <div className="mt-5">
            <Link to="/user/dashboard" className="text-warning">
                Back to Dashboard
            </Link>
        </div>
    );

    const loadTiket = () => {        
        getTiket(user._id, token).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setTikets(data);                
            }
        });
    };

    useEffect(() => {
        loadTiket();
    }, []);

    const filteredTiket = tikets.filter(tiketById =>{
            return user.email === tiketById.email;
        });
   
 
 return (

        <Layout
            title="Adauga o sesizare noua"
            description={`Buna ziua ${user.name}, te rugam sa notezi observatie?` }
        >
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h2 className="mb-4">Sesizarile mele</h2>
            <div className="row">
                {filteredTiket.map((tiket, i) => (
                    <div key={i} className="col-5 mx-auto my-2">

                        <Card tiket={tiket}  />
                    </div>
                ))}
            </div>
            
                    {goBack()}
                </div>
            </div>
        </Layout>
    );
};

export default MyTiket;