import React, { useState } from "react";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  ShopOutlined,
  InfoCircleOutlined,
  FolderOutlined,
  ProfileOutlined,
  TeamOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  UserAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { signout, isAuthenticated } from "../auth";



const { SubMenu, Item } = Menu;

const Header = () => {  
  const { user } = isAuthenticated();

  let history = useHistory();

 
  const logout = () => {   
    localStorage.removeItem("jwt");
    history.push("/");
  };

  return (
    <Menu mode="horizontal" theme="dark">
      <Item key="1" >
        <Link to="/">Acasa</Link>
      </Item>
      
      <Item key="2" >
        <Link to="/softeh">Software Farmacii</Link>
      </Item> 

      <Item key="3" >
        <Link to="/service">Web Design</Link>
      </Item>  

     
      {!user && (
        <Item key="4" icon={<UserOutlined />} className="float-right">
          <Link to="/signin">Contul meu</Link>
        </Item>
      )}

      {user && (
        <SubMenu
          icon={<SettingOutlined />}
          title={user.email && user.email.split("@")[0]}
          className="float-right"
        >
          {user && user.role === 0 &&  (
            <Item key="5" icon={<ProfileOutlined />}>
              <Link to="/user/dashboard">Dashboard</Link>
            </Item>
          )}

          {user && user.role === 1 && (
            <Item key="6" icon={<ProfileOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Item>
          )}

          {user && user.role === 899 && (
            <Item key="7" icon={<ProfileOutlined />}>
              <Link to="/tblue/dashboard">Tb Admin Page</Link>
            </Item>
          )}    

          <Item key="8" icon={<LogoutOutlined />} onClick={logout}>
            Logout
          </Item>
        </SubMenu>
      )}
    </Menu>
  );
};

export default Header;
