import React, { useState } from 'react';
import { Modal, Button } from 'antd';


const Confidentialitate = () => {  
        const [isModalVisible, setIsModalVisible] = useState(false);
      
        const showModal = () => {
          setIsModalVisible(true);
        };
      
        const handleOk = () => {
          setIsModalVisible(false);
        };
      
        const handleCancel = () => {
          setIsModalVisible(false);
        };
      
        return (
          <>
            <a onClick={showModal} className="text-foot text-light">Politica de confidentialitate</a>
            <Modal width={1000} title="Politica de confidentialitate" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
             <div>                 
                 <p><span className="text-primary h6">SC WEBNODE CONSULTING SRL,</span> cu sediul in Romania, str.Unirii, Nr.14, ap. 1, Stei, Bihor (denumita in continuare “WebNode”), 
                     administrator al site-ului web-node.ro declara ca toate datele cu caracter personal (denumite in continuare “datele”) sunt 
                     considerate ca strict confidentiale si sunt gestionate in conformitate cu Regulamentul (UE) nr. 679/2016 al Parlamentului 
                     European si al Consiliului privind protectia persoanelor fizice cu privire la prelucrarea datelor cu caracter personal si a 
                     liberei circulatii a datelor si de abrogarea a Directivei 95/46/EC (GDPR).Va rugam sa verificati, de asemenea, Politica privind utilizarea cookies pentru
                      mai multe informatii despre modalitatea in care folosim modulele cookie pe acest site.</p>
                     <p><span className="text-primary h6 ">Datele cu caracter personal si prelucrarea acestora</span><br/> Datele cu caracter personal reprezinta orice informatie 
                     cu privire la o persoana fizica identificata sau identificabila (“persoana vizata”); o persoana fizica identificabila este aceea care poate fi identificata, 
                     direct sau indirect, in special prin referire la un identificator precum nume, un numar de identificare, localizare, un identificator online sau prin referire la unul 
                     sau mai multe elemente specifice, proprii identitatii sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.</p>
                     <p><span className="text-primary h6 ">Categorii de date cu caracter personal</span><br /> In general, puteti folosi acest site fara a furniza nicio informatie. Cu toate acestea, 
                     pentru a raspunde solicitarilor trimise de dumneavoastra prin formularul de contact disponibil pe site, anumite informatii despre dumneavoastra vor fi necesare. In asemenea 
                     circumstante, colectam urmatoarele tipuri de date: (i) nume si (ii) e-mail. Totodata, in cazul in care transmiteti mai multe informatii personale prin campul “mesaj”, 
                     vom prelucra acele date, in functie de cererea dumneavoastra.</p>
                     <p><span className="text-primary h6 ">Scopul prelucrarii datelor cu caracter personal</span>
                     <ul>In primul rand, prelucram numele si adresa dumneavoastra de e-mail pentru a raspunde solicitarii.
                        In al doilea rand, putem prelucra datele cu caracter personal in urmatoarele scopuri:
                        <li>Pentru transmiterea unei oferte referitoare la serviciile noastre (in cazul in care solicitati o asemenea oferta);</li>
                        <li>In situatia in care avem consimtamantul dumneavoastra expres;</li>
                        <li>Pentru imbunatatirea serviciilor si sistemelor noastre (incluzand acest site) in baza interesului nostru legitim derivat din libertatea 
                            afacerilor si al interesului de a ne imbunatati serviciile pentru succesul concurential</li>
                    </ul></p>
                    <p><span className="text-primary h6 ">Transferul datelor cu caracter personal catre terte parti</span>
                     <ul>Nu vom transfera sau dezvalui datele dumneavoastra cu caracter personal catre terte parti, cu exceptia cazului in care transferul este impus sau permis 
                         de lege, daca este necesar pentru a raspunde oricarei solicitari din partea dumneavoastra. Cu toate acestea, vom transmite datele dumneavoastra catre 
                         terte parti atunci cand acest transfer este bazat pe o obligatie legala, interes legitim sau in situatia in care avem consimtamantul dumneavoastra prealabil, 
                         catre urmatoarele:
                        <li>Autoritati publice (ex. instante de judecata, politie, etc.)</li>
                        <li>Furnizori de servicii angajati de noi in vederea sprijinirii acordarii de servicii la cele mai inalte standarde (ex. furnizori de servicii IT, etc.)</li>                       
                    </ul></p>
                    <h5 className="text-danger">Securitatea datelor cu caracter personal si perioada de retentie</h5>
                    <p><span className="text-primary h6 ">Securitatea datelor personale</span><br />In vederea prevenirii accesului neautorizat sau al divulgarii datelor, am instituit proceduri fizice, electronice si manageriale pentru a proteja si securiza informatiile pe care le colectam online.
                        WebNode se angajeaza sa protejeze confidentialitatea informatiilor personale identificabile pe care le colectam de la dumneavoastra ca urmare a utilizarii site-ului.
                        WebNode a implementat standarde general acceptate de tehnologie si securitatea operatiunilor in vederea protejarii informatiilor personale identificabile impotriva pierderii 
                        utilizarii abuzive, alterarii sau distrugerii acestora.
                         Doar personalul autorizat WebNode are acces la informatii personale identificabile, angajatii si/sau colaboratorii avand obligatia de a trata informatiile ca fiind de natura 
                         confidentiala. Fara a aduce atingere acestor masuri de securitate, nu putem garanta ca persoane neautorizate nu vor incerca sa obtina acces la datele personale identificabile.
                        Mai mult, solicitam persoanelor imputernicite de noi sa demonstreze conformitatea sistemelor lor cu prevederile GDPR. </p>
                    <p><span className="text-primary h6 ">Durata prelucrarii</span>
                     <ul>Prelucram si stocam date cu caracter personal:
                        <li>pe toata perioada necesara pentru a raspunde solicitarii dumneavoastra trimise prin formularul de contact, dar nu mai mult de 3 luni de la solutionarea cererii;</li>
                        <li>pe toata perioada necesara realizarii scopului colectarii datelor;</li>
                        <li>pe toata perioada in care WebNode este obligata de lege;</li>
                        <li>in alte cazuri, prelucrarea datelor rezulta din scopul prelucrarii astfel cum este prevazut de lege</li>                       
                    </ul></p>
                    <p><span className="text-primary h6 ">Drepturile persoanelor vizate</span>
                     <ul>
                        <li><em className="text-primary">Dreptul de a fi informat</em> – aveti dreptul de a fi informat la momentul la care datele sunt colectate. Aceasta obligatie nu depinde de o solicitare din partea dumneavoastra, 
                            fiind datoria noastra de a ne conforma obligatiei, indiferent daca sunteti sau nu interesat de respectiva informatie.</li>
                        <li><em className="text-primary">Dreptul de acces </em> - aveti dreptul de a obtine din partea noastra, la cerere, informatii despre prelucrarea datelor dumneavoastra cu caracter personal, care va vor fi comunicate 
                        dumneavoastra intr-o maniera inteligibila.</li>
                        <li><em className="text-primary">Dreptul la rectificare </em>– aveti dreptul de a solicita rectificarea datelor dumneavoastra cu caracter personal. Acuratetea datelor este esentiala pentru noi in vederea asigurarii 
                        unui inalt nivel de protectie a datelor.</li>
                        <li><em className="text-primary">Dreptul la stergerea datelor (“dreptul de a fi uitat”)</em>– acest drept nu se aplica datelor cu caracter personal de care WebNode are nevoie pentru indeplinirea obligatiilor 
                        sale contractuale si/sau legale sau de a isi proteja interesele legitime.</li> 
                        <li><em className="text-primary">Dreptul la restrictionarea prelucrari</em>–  aveti dreptul de a restrictiona temporar prelucrarea datelor de catre noi. Puteti solicita restrictionarea prelucrarii atunci cand:
                                contestati acuratetea datelor personale;
                                prelucrarea este ilegala si solicitati restrictionarea prelucrarii datelor si nu stergerea acestora;
                                datele trebuie pastrate pentru exercitarea sau apararea pretentiilor legale;
                                o hotarare este in curs de solutionare cu privire la interesul legitim al operatorului care prevaleaza asupra celor ale persoanei vizate.</li> 
                        <li><em className="text-primary">Dreptul la portabilitatea datelor</em>– aveti dreptul de a solicita portabilitatea datelor in situatia in care datele furnizate catre noi sunt 
                        prelucrate prin mijloace automate pe baza consimtamantului sau atunci cand prelucrarea datelor este necesara pentru executarea unui contract si este efectuata prin mijloace 
                        automate. Acest lucru inseamna ca dreptul la portabilitatea datelor nu se aplica in situatiile in care prelucrarea datelor personale este intemeiata pe o prevedere legala, 
                        alta decat consimtamantul sau un contract.</li> 
                        <li><em className="text-primary">Dreptul de a formula obiectii</em>– puteti invoca dreptul de a formula obiectii privind prelucrarea datelor cu caracter personal in baza 
                        temeiurilor legate de situatia dumneavoastra particulara si in ceea ce priveste prelucrarea datelor pentru scopuri de marketing direct.</li>
                        <li><em className="text-primary">Drepturi referitoare la luarea deciziilor si profilarea automata</em>– deciziile automatizate sunt decizii luate utilizand date prelucrate doar prin mijloace automate fara interventie umana. WebNode nu 
                        prelucreaza date in aceasta modalitate.</li>                      
                    </ul></p>
                    <p><span className="text-primary h6 ">Date de contact</span><br /> Daca aveti orice intrebari, comentarii sau solicitari referitoare la aceasta Politica sau intentionati sa va exercitati drepturile anterior
                     mentionate, va rugam sa nu ezitati sa ne contactati la adresele mentionate mai jos.</p>
                     <em><span className="text-primary h6 ">Webnode Consulting SRL</span><br />Romania, Bihor, str. Unirii, nr. 14, ap. 1, webnode.consulting@gmail.com</em>
                </div>
            </Modal>
          </>
        );      
};

export default Confidentialitate;