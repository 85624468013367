import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Layout from "../../core/Layout";
import { signin, authenticate, isAuthenticated } from "../../components/auth";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { MailOutlined, GoogleOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const Signin = () => {
    const [values, setValues] = useState({
        email: "",
        password: "",        
        error: "",
        loading: false,
        redirectToReferrer: false
    });
    const [loading, setLoading] = useState(false);

    const { email, password, error, redirectToReferrer } = values;
    const { user } = isAuthenticated();
   

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = event => {
        setLoading(true);
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });
        signin({ email, password }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
                toast.error(data.error);
                setLoading(false);
            } else {
                setLoading(false);
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });                   
                });
            }
        });
    };

    const signUpForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">                
                <input
                    onChange={handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                    placeholder="Your email"
                    autoFocus
                />
            </div>

            <div className="form-group">                
                <input
                    onChange={handleChange("password")}
                    type="password"
                    className="form-control"
                    value={password}
                    placeholder="Your password"
                />
            </div>
            <Button
                onClick={clickSubmit}
                type="primary"
                className="mb-3"
                block
                shape="round"
                icon={<MailOutlined />}
                size="large"                
            >
                Login with Email/Password
            </Button>
           
        </form>
    );

   
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>Loading...</h2>
            </div>
        );
            
    const redirectUser = () => {
        if (redirectToReferrer) {
            if (user && user.role === 0) {
                return <Redirect to="/user/dashboard" />;
            } else if (user && user.role === 1) {
                return <Redirect to="/admin/dashboard" />;
            } else if (user && user.role === 899) {
                return <Redirect to="/tblue/dashboard" />;
            }
        }
        if (isAuthenticated()) {
            return <Redirect to="/" />;
        }
    };

    return (
        <Layout
            title="Autentificare "
            description="Autentificare la WebNode Consulting"            
        >
            <div className="container p-5">
                <div className="row" style={{ minHeight: '65vh' }}>                
                    <div className="col-md-6 offset-md-3">
                        {loading ? (
                            <h4 className="text-danger">Loading...</h4>
                        ) : (
                           <div className="d-flex justify-content-center"> <img src="/img/logo2.png" style={{ height: "150px", width: "150px" }} className="py-4"/></div>
                        )}
                        {signUpForm()}
                        {redirectUser()}
                    </div>
                </div>
            </div>    
        </Layout>
    );
};

export default Signin;
