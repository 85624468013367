import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TopPage = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4" style={{background: "#292b2c"}}>
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-4 text-center" >                        
                    <img className="card-img-top py-4" src="/img/softeh/head1.png" alt="SoftehPlus" style={{ width: "10rem" }}></img> 
                    <h5 className="text-light mt-4">27 ani de plus in tehnologie</h5>
                    </div>                 
                   
                    <div className="col-md-8 text-center  text-light" >      
                    <h5 className="text-light mt-4"></h5>                  
                        <p style={{ textAlign: "justify" }}><span className="text-light h5">Softeh Plus, </span><br /> este printre primele companii private cu capital 100% romanesc
                         din domeniu. In tot acest timp ne-am dedicat dezvoltarii si implementarii de solutii software adecvate in domenii de activitate precum: farmacii, constructii, 
                         sanatate, institutii publice, contabilitate, gestiune, comert.</p>                        
                        <p style={{ textAlign: "justify" }}><span className="text-light h5">Cronologie, </span> <br />Compania Softeh Plus debuteaza in anul 1993 pe piata software din Romania
                         cu aplicatia pentru devize-oferte, destinata domeniului constructiilor. Acest sistem informatic denumit in prezent WindevRO, a fost imbunatatit in mod constant, devenind 
                         in prezent una dintre cele mai apreciate solutii software ale acestui domeniu. Acest succes este datorat modalitatii in care Softeh Plus trateaza si raspunde la cerintele 
                         specifice acestui domeniu. Anul 2000 este marcat de o extindere puternica in domeniul farmaciilor, cu sisteme informatice specializate in gestiunea activitatii farmaciilor 
                         independente si lanturilor de farmacii. In perioada 2003 – 2006, dezvoltarea ce vizeaza domeniul serviciilor medicale adauga pe lista activitatea de spitale, clinici si 
                         laboratoare. Dorinta de a oferi o solutie informatica completa s-a concretizat in anul 2009, atunci cand Softeh Plus a dezvoltat o solutie completa ERP 
                         (Enterprise Resource Planning) ce tine cont de particularitatile pietei romanesti, capabila sa asigure o gestionare completa a informatiilor existente in cadrul unei organizatii.</p>
                        <p style={{ textAlign: "justify" }}><span className="text-light h5">Misiune, </span> <br />Dorim sa transformam fiecare client intr-un partener de incredere, 
                        cu care sa avem o relatie stabila, bazandu-ne pe cele mai potrivite solutii software care sa le asigure clientilor nostri puterea de a-si atinge obiectivele stabilite. </p>
                        <p style={{ textAlign: "justify" }}><span className="text-light h5">Viziune, </span> <br />Vom continua sa ne aliniem trendurilor globale in materie de software, 
                        si sa perseveram prin rezolvarea problemelor intampinate de cei care se bazeaza pe sisteme informatice in cadrul activitatii lor. </p>
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default TopPage;