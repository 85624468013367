import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import Bottom from "../../components/home/Bottom"
import TopPage from "../../components/webnode/TopPage"
import NeedHelp from "../../components/webnode/NedHelp"
import Avantaje from "../../components/softeh/Avantaje"
import Portofolio from "../../components/webnode/Portofolio"
import BottomPage from "../../components/webnode/BottomPage"
import AvantajeWeb from "../../components/webnode/Avantaje"
import Webdesign from "../../components/webnode/WebDesign";
import { getPortofolios } from "../../functions/portofolio";


const Webnode = () => {    
    const [portofolios, setPortofolios] = useState([]); 

    useEffect(() => {
        loadPortofolios();
      }, []);
    
      const loadPortofolios = () =>
        getPortofolios().then((p) => setPortofolios(p.data));

        console.log(portofolios)

    return (
        <Layout
            title="WebNode Consulting"
            description="Consultant IT  |  Reprezentant Softeh"            
        >            
            <div className='main main-raised'>
                <div className="">
                    <Webdesign />
                    <AvantajeWeb />
                    <Portofolio portofolios={portofolios} />                    
                    <BottomPage />
                </div>
            <Bottom />
            </div>
        </Layout>
    );
};

export default Webnode;