import React from 'react';
import ConfidentModal from "../../pages/consulting/Confidentialitate";
import PrivacyModal from "../../pages/consulting/privacy";
import About from "../../pages/consulting/AboutAs";
import FAQ from "../../pages/consulting/FAQ";
import Services from "../../pages/consulting/Services";
import { FacebookOutlined, LinkedinOutlined } from "@ant-design/icons";


const Bottom = () => {    
    
    
    return (
        <div>
            <div className="mt-5">
                <div className="shape overflow-hidden text-footer">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="#292b2c"></path>
                    </svg>
                </div>
            </div>
            <div >    
            <footer className="TextStyle" style={{background: "#292b2c"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <h4 className="text-light footer-head">Urmareste-ne pe</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><a href="/" className="text-foot text-light"> <FacebookOutlined className="h5" /> <span className="ml-2"> Facebook </span></a></li>                                    
                                <li><a href="/" className="text-foot text-light"> <LinkedinOutlined className="h5" /> <span className="ml-2"> Linkedin </span></a></li>                                                 
                            </ul>  
                        </div>
                        <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-light footer-head">WebNode</h4>
                            <ul className="list-unstyled footer-list mt-4">
                            <li><About /></li>
                            <li><FAQ /></li>
                            <li><Services /></li>                                                                                 
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-light footer-head">Link-uri Utile</h4>
                            <ul className="list-unstyled footer-list mt-4">
                                <li><ConfidentModal /></li>
                                <li><PrivacyModal /></li>
                                <li><a href="/signin" className="text-foot text-light"> Login</a></li>
                            </ul>                            
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h4 className="text-light footer-head">Contact</h4>
                            <ul className="list-unstyled footer-list mt-4 text-light">
                                <li> Support Phone: 0727 692 663</li>
                                <li> Marketing Phone: 0770 390 826</li>
                                <li>Email: webnode.consulting@gmail.com</li>
                                <li> Primary Address: Bihor, Stei, str. Unirii, nr. 14/1</li>
                            </ul>               
                        </div>
                    </div>
                </div>   
            </footer>
            </div>
        </div>        
    );
};

export default Bottom;