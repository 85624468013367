import React, { useState, useEffect } from "react";
import Layout from "../../../core/Layout";
import { isAuthenticated } from "../../../components/auth";
import { createTiket, getTikets } from "../../../functions/tiket";
import { sendMail, sendSms } from "../../../functions/send";
import UserNav from "../../../components/nav/UserNav";
import { LoadingOutlined, SendOutlined  } from "@ant-design/icons";
import { toast } from "react-toastify";


const CreateTiket = () => {
    const [observation, setObservation] = useState("");
    const [loading, setLoading] = useState(false);
    const { user, token } = isAuthenticated();
    const [tikets, setTikets] = useState([]); 
    const [values] = useState({
        name: user.name,
        email: user.email,
        company: user.company, 
        cname: user.cname,
        workstation: user.workstation,
        wname: user.wname,
        phone: user.phone,
        tiketid: `${new Date().getTime()}`
    });    

    useEffect(() => {
        loadTikets();
      }, []);
    
    const loadTikets = () =>
        getTikets().then((t) => setTikets(t.data));

    const handleChange = e => {        
        setObservation(e.target.value);
    };
    
    const clickSubmit = e => {
        e.preventDefault();
        setLoading(true);              
        let redirectLink = "https://web-node.ro/admin/dashboard";
        let adminPhone = "+40727692663";
        createTiket(user._id, token, { observation, ...values }).then(data => {
            if (data.error) {
                setLoading(false);
                toast.error(data.error);
            } else {
                setLoading(false);
                toast.success(`Sesizare depusa cu succes`);              
                const createSendTiket = { 
                    name: values.name, 
                    tiketid: values.tiketid,
                    email: values.email, 
                    company: values.cname, 
                    workstation: values.wname, 
                    phone: values.phone,                     
                    observation: observation }; 
                const message = {  
                    to: adminPhone, 
                    body:  `Sesizare: ${values.cname}`                                                               
                    };
                sendMail(user._id, token, createSendTiket);
                sendSms(user._id, token, message);          
            }
            setObservation("");
        });
    };

    const newTiketForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Noteaza observatie</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={observation}
                    autoFocus
                    required
                />
            </div>                            
            <button className="btn btn-outline-primary">Depune Sesizare</button>
        </form>
    );   

    return (
        <Layout            
            description={`Buna ziua ${user.name}, te rugam sa notezi observatie.`}
            className="container-fluid"
        >
            <div className="row" style={{ minHeight: '75vh' }}>
            <div className="col-md-3">
                        <UserNav />
                    </div>
                <div className="col-md-8 py-4">
                {loading ? (
                        <LoadingOutlined className="text-danger h1" />
                        ) : (
                        <h4>Depune o sesizare</h4>
                        )}
                        <hr />                    
                    {newTiketForm()}                    
                </div>
            </div>
        </Layout>
    );
};

export default CreateTiket;
