import React from "react";
//import { Link } from "react-router-dom";
//import ShowImage from "./ShowImage";
//import moment from "moment";
//import { addItem, updateItem, removeItem } from "./cartHelpers";
import styled from 'styled-components';
//import {ButtonContainer} from './Button';

const Card = ({
    tiket,   
    linkCard = true
}) => {
     
    const showsLinkCard = linkCard => {
        return (
            linkCard && (
                <div className="card" style={{ borderBottom: "5px solid indigo" }}>          
                <div className="card-footer">
                <p className="lead mt-2 ">
                    <span className="align-self-center text-title mb-0">{tiket.farmacie} </span>   <br/>
                    <span className="font-italic text-danger mb-0 ">{tiket.status} </span>                
                </p>
                <div className="card-footer d-flex justify-content-between">
                    
                        <h5 className="text-blue font-italic mb-0">
                            {tiket.observation}
                        </h5>
                </div>  
                <br />              
                <h5 className="text-danger font-italic mb-0">
                Punct de lucru:  {tiket.plucru},  <span className="mr-1">Farmacist {tiket.name}, </span>{tiket.phone}, {tiket.email}</h5>
                </div>
             
        </div>
            )
        );
    };
   

    return (
        <ProductWrapper className=" mx-auto ">
            {showsLinkCard(linkCard)}           
        </ProductWrapper>
    );
};

const ProductWrapper = styled.div`
    .card{
        border-color:transparent;
        transition:all 1s linear;
    }
    .card-footer{
        background: transparent;
        border-top: transparent;
        transition:all 1s linear;
    }
    &:hover{
        .card{
            border:0.04rem solid rgba(0,0,0,0.2);
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
        }
        .card-footer{
            background:rgba(247,247,247);
        }
        
    }
    .img-container{
        position:relative;
        overflow:hidden;
    }
    .card-img-top{
        transition: all 1s linear;
    }
    .img-container:hover .card-img-top{
        transform:scale(1.2);
    }
    .cart-btn{
        position:absolute;
        bottom: 0;
        right: 0;
        padding: 0.2rem 0.4rem;
        background: var(--lightBlue);
        border: none;
        color: var(--mainWhite);
        font-size: 1.4rem;
        border-radius: 0.5rem 0 0 0;
        transform: translate(100%, 100%);
        transition:all 1s linear;
    }
    .img-container:hover .cart-btn{ 
        transform: translate(0, 0); 
        
    }
    .cart-btn:hover {
        color: var(--mainBlue);
        cursor: pointer;
    }

    .addButton {
  text-transform:capitalize;
  font-size:1.2rem;
  background: transparent;
  border:0.05rem solid var(--lightBlue);
  border-color: var(--lightBlue);
  color: var(--lightBlue);
  border-radius:0.5rem;
  pading: 0.2rem 0.5rem;
  cursor:pointer;
  margin: 0.2rem 0.5rem 0.2rem 0;
  transition: all 0.5s ease-in-out;
  &:hover{
    background: var(--lightBlue);
    color:var(--mainWhite);
  }
  &:focus{
    outline:none;
  }
    }
`;

export default Card;
