import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../core/Layout";
import TbNav from "../../components/nav/TbNav";
import { toast } from "react-toastify";
import { isAuthenticated } from "../../components/auth";
import { createTbProduct, getTbProducts, removeTbProduct } from "./tbAPI";
import LocalSearch from "../../components/forms/LocalSearch";
import { EditOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons";


import { LoadingOutlined } from "@ant-design/icons";

const initialState = {
  title: "",    
  price: "",
  description: "",  
};

const OptionalCreate = () => {
  const [values, setValues] = useState(initialState);  
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);  
  const [keyword, setKeyword] = useState("");

  const { user, token } = isAuthenticated();
  const { title, price, description } = values

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = () =>
    getTbProducts().then((c) => setProducts(c.data));


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createTbProduct(values, user._id, token)
      .then((res) => {
        setLoading(false);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        // if (err.response.status === 400) toast.error(err.response.data);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    // console.log(e.target.name, " ----- ", e.target.value);
  };

  const handleRemove = async (slug) => {
    // let answer = window.confirm("Delete?");
    // console.log(answer, slug);
    if (window.confirm("Delete?")) {
      setLoading(true);
      removeTbProduct(slug, user._id, token)
        .then((res) => {
          setLoading(false);
          toast.error(`${res.data.title} deleted`);
          loadProducts();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };

  const searched = (keyword) => (c) => c.title.toLowerCase().includes(keyword);
  

  return (
    <Layout      
      description={`Buna ziua ${user.name}, aici poti sa creezi un produs nou.`}
      >      
      <div className="">
        <div className="row" style={{ minHeight: '80vh' }}>
          <div className="col-md-3">
            <TbNav />
          </div>

          <div className="col-md-9">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Product create</h4>
            )}
            <hr />
            <form onSubmit={handleSubmit}>
                <div className="container-fluid">
                    <div className="form-group">
                        <label>Title</label>
                        <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={title}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Price</label>
                        <input
                        type="number"
                        name="price"
                        className="form-control"
                        value={price}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <input
                        type="text"
                        name="description"
                        className="form-control"
                        value={description}
                        onChange={handleChange}
                        />
                    </div>                                     
                    <br />
                    <button className="btn btn-outline-info">Save</button>
                </div>
            </form>
            <LocalSearch keyword={keyword} setKeyword={setKeyword} />
            {/* step 5 */}
            {products && products.filter(searched(keyword)).map((c) => (
              <div className="alert alert-secondary" key={c._id}>
                {c.title} - {c.price} RON
                <span
                  onClick={() => handleRemove(c.slug)}
                  className="btn btn-sm float-right"
                >
                  <DeleteOutlined className="text-danger" />
                </span>
                <Link to={`/admin/effect/${c.slug}`}>
                  <span className="btn btn-sm float-right">
                    <EditOutlined className="text-warning" />
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>      
    </Layout>
  );
};

export default OptionalCreate;
