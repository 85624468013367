import axios from "axios";
import { API } from "../config";

export const createContact = async (contact) =>
  await axios.post(`${API}/contact/create`, contact );

export const removeContact = async (slug, userId, token) =>
  await axios.delete(`${API}/contact/${slug}/${userId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    },
  });

export const getContact = async (slug, userId, token) =>
  await axios.get(`${API}/contact/${slug}/${userId}`, {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
  });

export const getContacts = async (userId, token) =>
  await axios.get(`${API}/contacts/${userId}`, {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      },
  });

