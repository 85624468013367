import React, { useState } from 'react';
import { Modal } from 'antd';


const About = () => {  
        const [isModalVisible, setIsModalVisible] = useState(false);
      
        const showModal = () => {
          setIsModalVisible(true);
        };
      
        const handleOk = () => {
          setIsModalVisible(false);
        };
      
        const handleCancel = () => {
          setIsModalVisible(false);
        };
      
        return (
          <>
            <a onClick={showModal} className="text-foot text-light">Despre Noi</a>
            <Modal width={1000} title="WEBNODE CONSULTING SRL" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
             <div>                 
                 <p><span className="text-primary h1">In constructie!</span> </p>
                     
                    <p><span className="text-primary h6 ">Date de contact</span><br /> Daca aveti orice intrebari, comentarii sau solicitari, va rugam sa nu ezitati sa ne contactati la adresele mentionate mai jos.</p>
                     <em><span className="text-primary h6 ">Webnode Consulting SRL</span><br />Romania, Bihor, str. Unirii, nr. 14, ap. 1, webnode.consulting@gmail.com</em>
                </div>
            </Modal>
          </>
        );      
};

export default About;