import React from "react";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TopPage = () => {    

    function callback(key) {
        console.log(key);
      }
    
    return (
        <div>
            <div className="container-fluid TextStyle py-4 bg-light"  >
            <div className="row d-flex justify-content-center" >
                    <div className="col-md-4 mt-4 text-center" >                        
                    <img className="card-img-top py-4" src="/img/logo2.png" alt="SoftehPlus" style={{ width: "10rem" }}></img> 
                    <h5 className="text-dark mt-4">SERVICII DE SUPORT TEHNIC SI ASISTENTA TEHNICA</h5>
                    </div>                  
                   
                    <div className="col-md-8 mt-4 text-center text-muted" >      
                    <h5 className="text-light mt-4"></h5>                  
                        <p style={{ textAlign: "justify" }}><span className="text-dark h5">Suport tehnic IT, </span><br/> presupune oferirea de suport tehnic 
                        telefonic pentru anumite tipuri de defecte. Nu toate problemele presupun deplasarea sau conectarea remote a unui tehnician .
                                Cand se detecteaza o problema se deschide o sesiune de suport si se
                                incearca depanarea pe loc. Acest lucru elimina timpii de deplasare, cheltuielile
                                aferente si timpii de nefunctionare ai sistemului de calcul. Acest tip de ajutor este de
                                asemenea disponibil cand lucrati in deplasare sau de acasa.</p>
                        <p style={{ textAlign: "justify" }}><span className="text-dark h5">Asistenta tehnica remote, </span> <br/> 
                                permite informaticianului sa acceseze sistemul de calcul de la
                                dinstanta folosind conexiuni prin Internet securizate. Informaticianul care ofera acest tip de
                                suport poate sta in fata calculatorului in celelalt capat al orasului sau oriunde altundeva cu
                                conditia existentei unei conexiuni la Internet. Utilizatorul permite accesul informaticianului
                                pe calculatorul sau pentru a diagnostica si repara probleme aparute, pentru a instala /
                                dezinstala aplicatii sau pentru a face actualizari. Folosind aceasta metoda calculatorulu nu
                                paraseste biroul si nimeni nu trebuie sa se deplaseze pentru remedierea problemelor. </p>                        
                    </div>                                               
                                       
                </div>
        </div>        
        </div>        
    );
};

export default TopPage;