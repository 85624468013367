import axios from "axios";
import { API } from "../config";


export const createContact = async (contact) =>
    await axios.post(`${API}/contact/create/`, contact);

export const getCompanys = async () =>
    await axios.get(`${API}/contacts`);

export const sendMail = async (userId, token, email) => 
    await axios.post(`${API}/email/send/${userId}`, email, {
        method: "POST",
        headers: {
            Accept: "application/json",            
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(email)
    });       

export const sendSms = async (userId, token, sms) => 
    await axios.post(`${API}/messages/${userId}`, sms, {
            method: "POST",
            headers: {
                Accept: "application/json",                
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(sms)
    });
            
