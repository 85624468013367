import React from "react";
import TbNav from "../../components/nav/TbNav";
import HeliumDash from "../../components/tblue/HeliumDashboard";
import Layout from "../../core/Layout";
import { isAuthenticated } from "../../components/auth";
import Configuration from "./Configuration";
import { ImportOutlined } from "@ant-design/icons";


const TbDashboard = () => {





  return (
    <Layout     
    description={`G'day ${isAuthenticated() && isAuthenticated().user.name}! `}
  >    
    <div className="row" style={{ minHeight: '80vh' }}>
      <div className="col-md-3">
        <TbNav />
      </div>
      <div className="col-md-9">    
          
          <HeliumDash />
      </div>
    </div>    
  </Layout>
  );
};

export default TbDashboard;